import { IonBackButton, IonButtons, IonContent, IonHeader, IonImg, IonItem, IonPage, IonToolbar } from "@ionic/react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { IEvidence, IUser, ProgramData, UsageData } from "../../Interfaces";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import { IMandatoryStandard, IOptionalStandard } from "../../Interfaces";
import { Capacitor } from "@capacitor/core";
import { HeaderTitle } from "parafolio-components";
import { Icon_Add_Evidence, Icon_Tick_Green } from "../../assets/images";
import programsData from "../../assets/json/programData.json";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import DataController from "../../controllers/DataController";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import * as ProgramUtils from "../../utils/programUtils";
import _ from "lodash";
import { FirebaseService } from "../../controllers/FirebaseService";
import { EventRegister } from "react-native-event-listeners";
import { PMUsageData, ProgramModule } from "pf-support-lib";

const StandardPage: React.FC = (props) => {
  const params = useParams<{ programId: string; standardId: string }>();
  const history = useHistory();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const [programEvidence, setProgramEvidence] = useState<IEvidence[]>([]);
  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [selectedStandard, setSelectedStandard] = useState<IMandatoryStandard | IOptionalStandard>();
  const [showDescriptions, setShowDescriptions] = useState(false);
  const [numberOfLearningObjectivesToShow, setNumberOfLearningObjectivesToShow] = useState(3);
  const [progress, setProgress] = useState<number>(0);

  useEffect(() => {
    const allPrograms = programsData;
    const _program = allPrograms.find((item: any): item is ProgramData => item.ID === params.programId);

    if (_program) {
      setProgram(_program);

      // get selected standard
      const roleId = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);
      const mandatoryStandards = ProgramUtils.getMandatoryStandardsFromProgram(_program, roleId);
      const optionalStandards: IOptionalStandard[] = ProgramUtils.getOptionalStandardsFromUser(user.contents, _program);
      const allStandards = [...mandatoryStandards, ...optionalStandards];
      const selectedStandard = allStandards.find((s) => s.id === params.standardId);

      setSelectedStandard(selectedStandard);
    }
  }, []);

  useEffect(() => {
    let evidenceForProgram: IEvidence[] = ProgramUtils.getEvidenceForProgramName(evidence.contents, program?.Name);

    const filteredEvidence = evidenceForProgram.filter((evidence) => {
      try {
        const parsedEvidence = JSON.parse(evidence.evidenceJSON);
        const selectedStandardExistsInEvidence = Array.from(parsedEvidence.Comps).find(
          (item: any) => item.id === params.standardId
        );
        return selectedStandardExistsInEvidence ? true : false;
      } catch (error) {
        return false;
      }
    });
    setProgramEvidence(filteredEvidence);
  }, [evidence, program]);

  useEffect(() => {
    const calculateUsageData = () => {
      if (!program || !ProgramUtils.shouldCalculateUsageData(program.ID)) return;

      const nwasIC: any = programsData.find((program) => program.ID === "LR0HYZYWY6JQK");
      const pm = new ProgramModule(nwasIC);

      const contactID = Number(user.contents.userData.contactID);
      const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);

      const pMUsageData = new PMUsageData(nwasIC, []);

      const filteredEvidence = programEvidence.filter((ev) => !ev.draft) as [];

      const results = pMUsageData.getUsageDataByUser(pm, contactID, roleID, filteredEvidence);
      const resultForCurrentStandard = results.find((result) => result.compId === params.standardId);

      if (resultForCurrentStandard) {
        setProgress(Math.floor(Number(resultForCurrentStandard.percentage)));
      } else {
        setProgress(0);
      }
    };

    calculateUsageData();
  }, [programEvidence]);

  const evidenceCardClicked = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const programID = program?.ID;
    // const skillID = competence.ID;
    const skillID = selectedStandard?.id;

    history.push(`/dashboard/program/${programID}/${skillID}/read/${_evidence.id}`);
  };

  const addEvidenceForStandard = (): void => {
    const evidenceDefinitions = program["Evidence Definitions"] ?? [];

    const mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program);
    const optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program);

    const data = {
      program: program,
      ...(evidenceDefinitions.length > 0 && {
        evidenceDefinitions: evidenceDefinitions[0],
      }),
      ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
      ...(optionalStandards.length > 0 && { optionalStandards }),
      selectedStandard,
    };

    FirebaseService.logEvent("program_add_enhanced_evidence", {
      program: program?.Name,
    });

    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const getLearningObjectCount = (learningObjectiveIdToFind: string) => {
    let count = 0;
    programEvidence
      .filter((item) => !item.draft)
      .forEach((evidence) => {
        try {
          const parsedEvidence = JSON.parse(evidence.evidenceJSON);
          const comps = Array.from(parsedEvidence.Comps);
          const selectedStandardFound = comps.find((comp: any) => comp.id === selectedStandard?.id);
          if (selectedStandardFound) {
            const learningObjectivesForSelectedStandard = Array.from(parsedEvidence.LOs);
            const loFound = learningObjectivesForSelectedStandard.find(
              (lo: any) => lo.id === learningObjectiveIdToFind
            );
            if (loFound) count += 1;
          }
        } catch (error) {
          console.log("Failed to get update count for evidence");
        }
      });
    return count;
  };

  const getProgressStyle = () => {
    let textStyle = "text-grey-90";
    if (progress < 75) {
      textStyle = "text-progress-not-compliant-text";
    } else if (progress < 90) {
      textStyle = "text-progress-partially-compliant-text";
    } else {
      textStyle = "text-progress-compliant-text";
    }

    return textStyle;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{selectedStandard?.name}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground">
        {/* <pre style={{ maxHeight: 200 }}>{JSON.stringify(selectedStandard, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(program, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(user, null, 2)}</pre> */}
        <ProgramInfoCard title={selectedStandard?.name || ""}>
          <div className="text-acc-13px font-acc-400 text-grey-70 mt-1">Description</div>
          <div className="text-acc-17px font-acc-400 text-grey-90">{selectedStandard?.description}</div>
          <div className="text-acc-13px font-acc-400 text-grey-70 mt-3">NOS Progress</div>
          <div className={`font-bold ${getProgressStyle()}`}>
            <span className="text-[34px]">{progress}</span>
            <span className="text-[15px]">{" %"}</span>
          </div>
          <div className="text-acc-13px font-acc-400 text-grey-70 mt-3">
            Performance Criteria / Knowledge & Understanding
          </div>
          <IonItem detail={false} className="mt-3 removeItemPadding border-solid border-t border-item-border">
            <div className="w-full flex justify-between">
              <button
                className="text-acc-15px font-acc-400 text-cta-blue"
                onClick={() => setShowDescriptions(!showDescriptions)}
              >
                {showDescriptions ? "Hide" : "See"} descriptions
              </button>
              <button
                className="text-acc-15px font-acc-400 text-cta-blue"
                onClick={() =>
                  setNumberOfLearningObjectivesToShow(
                    numberOfLearningObjectivesToShow <= 3 ? selectedStandard!.learningObjectives.length : 3
                  )
                }
              >
                {numberOfLearningObjectivesToShow <= 3
                  ? `Show all ${selectedStandard?.learningObjectives.length}`
                  : "Show less"}
              </button>
            </div>
          </IonItem>
          {selectedStandard?.learningObjectives.slice(0, numberOfLearningObjectivesToShow).map((lo) => {
            const count = getLearningObjectCount(lo.ID);
            return (
              <IonItem key={lo.id} button={false} detail={false} className="removeItemPadding">
                <div className="w-full flex justify-between py-3">
                  <div>
                    <div className="text-acc-15px font-acc-600 text-grey-90">{lo.Name}</div>
                    {showDescriptions && (
                      <div className="text-acc-11px font-acc-400 text-grey-90 mt-1">{lo.Description}</div>
                    )}
                  </div>
                  <div className="flex items-center justify-center">
                    {count >= 1 && <IonImg className="h-[18px] w-[18px] mr-2" src={Icon_Tick_Green} />}
                    <div
                      className={`flex justify-center items-center text-acc-15px font-bold ${count >= 1 ? "text-green-light-text" : "text-grey-50"}`}
                    >
                      {`${count}/1`}
                    </div>
                  </div>
                </div>
              </IonItem>
            );
          })}
        </ProgramInfoCard>
        {/* <pre>{JSON.stringify(programEvidence, null, 2)}</pre> */}
        {/* <pre>{JSON.stringify(selectedStandard, null, 2)}</pre> */}
        {ProgramUtils.showEvidenceBlock(program?.ID, user.contents) && (
          <ProgramInfoCard
            title="Evidence"
            subTitle={programEvidence.length === 0 ? "Any evidence tagged with this NOS will appear below" : undefined}
            style={{ paddingTop: 14, paddingBottom: 8 }}
          >
            <ProgramCardButton
              buttonPressed={() => addEvidenceForStandard()}
              buttonText="Add new evidence"
              icon={Icon_Add_Evidence}
            />
            {programEvidence.length > 0 && (
              <div>
                {programEvidence.map((item) => (
                  <ProgramEvidenceCard
                    key={item.id}
                    buttonPressed={() => evidenceCardClicked(item)}
                    programEvidence={item}
                    program={program}
                  />
                ))}
              </div>
            )}
          </ProgramInfoCard>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StandardPage;
