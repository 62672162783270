import React, { useEffect, useState } from "react";
import { IEvidence, IUserProgramCertificate, ProgramCertificate } from "../../Interfaces";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom } from "../../state/State";
import { IonImg, IonItem } from "@ionic/react";
import { Button_Disclosure_Right_Grey } from "../../assets/images";
import "../../theme/tailwind.css";
import { StringUtils } from "../../utils/stringUtils";
import { ICompetenceItem } from "../../types/Components";

const CompetenceItem: React.FC<ICompetenceItem> = (props) => {
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const [competenceEvidence, setCompetenceEvidence] = useState<IEvidence[]>([]);
  const [competenceCertificate, setCompetenceCertificate] = useState<ProgramCertificate | null>(null);
  const [userCertificate, setUserCertificate] = useState<IUserProgramCertificate | null>(null);

  useEffect(() => {
    const getUserCertificate = (): void => {
      const competenceName = props.competence.Name;

      const certificateForCompetence: any = props.certificates.find(
        (cert: ProgramCertificate) => cert.Name === competenceName
      );
      const certificate = props.programCertificates.find((cert: IUserProgramCertificate) => {
        return cert.programData.programName === props.programName && cert.programData.competenceName === competenceName;
      });

      setCompetenceCertificate(certificateForCompetence);
      certificate && setUserCertificate(certificate);
    };

    if (props.certificates.length > 0) {
      getUserCertificate();
    }
  }, [props.certificates, props.competence, props.programCertificates, props.programName]);

  useEffect(() => {
    const getCompetenceEvidence = (): void => {
      const competenceName = props.competence.Name;

      let array: IEvidence[] = allEvidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const evidenceCompetenceName = evidenceJSON?.programInfo?.competence || "";

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return competenceName === evidenceCompetenceName && onHoldReset !== 1 && evidence.draft !== true;
      });

      setCompetenceEvidence(array);
    };

    if (allEvidence.state === "hasValue" && allEvidence.contents) {
      getCompetenceEvidence();
    }
  }, [allEvidence, props.competence]);

  return (
    <IonItem
      button
      className="!flex flex-col !w-full !shadow-unset !pl-0 part program-skill-button"
      detail={false}
      key={props.competence.id}
      onClick={props.buttonPressed}
    >
      <div className="w-full pl-0">
        <div className="flex flex-row justify-between items-center shadow-program-skill shadow-grey-30 py-[8px]">
          <div className="flex flex-col max-w-half">
            <div className="font-inter text-[15px] leading-[1.33] tracking-[-0.3px] text-grey-90">
              {props.competence.Name}
            </div>
            <div className="font-inter text-[11px] font-medium leading-[1.27] text-grey-60">
              {`${competenceEvidence.length} evidence`}
            </div>
          </div>
          <div className="flex flex-row items-center">
            <div
              className={StringUtils.getCertificatePillContainerClass(
                userCertificate,
                competenceCertificate?.Duration,
                true
              )}
            >
              {StringUtils.getCertificatePillString(userCertificate, competenceCertificate?.Duration, true)}
            </div>
            <IonImg className="h-[24px] w-[24px]" src={Button_Disclosure_Right_Grey} />
          </div>
        </div>
      </div>
    </IonItem>
  );
};

export default CompetenceItem;
