import { IonRouterOutlet } from "@ionic/react";
import React from "react";
import { Redirect, Route } from "react-router";
import Login from "./pages/login/Login";
import Welcome from "./pages/login/Welcome";
import Register from "./pages/login/Register";

const OnboardingRoutes: React.FC = () => {
  return (
    <IonRouterOutlet id="onboardingRouterOutlet" key="onboardingRouterOutlet" ionPage={true}>
      <Route exact path="/login" render={() => <Redirect to="/onboarding" />} />
      <Route exact path="/onboarding/login" render={() => <Login />} />
      <Route exact path="/onboarding/register" render={() => <Register />} />
      <Route exact path="/onboarding" render={() => <Welcome />} />
      <Route exact path="/dashboard" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/nos-overview" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/HART" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/program/:id" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/program/:id/:id" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/program/:id/:id/:id" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/dashboard/info/:id" render={() => <Redirect to="/onboarding/login" />} />
      <Route
        exact
        path="/:tab(dashboard)/program/:id/progress-check/:id"
        render={() => <Redirect to="/onboarding/login" />}
      />
      <Route
        exact
        path="/:tab(dashboard)/program/:id/progress-check/:id/comments"
        render={() => <Redirect to="/onboarding/login" />}
      />
      <Route
        exact
        path="/:tab(dashboard)/program/:id/progress-check/:id/read/:id"
        render={() => <Redirect to="/onboarding/login" />}
      />
      <Route exact path="/evidence" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/evidence/read/:id" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/guidelines" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/timeline" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/profile" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/profile/edit" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/profile/app-info" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/profile/account" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/profile/accessibility" render={() => <Redirect to="/onboarding/login" />} />
      <Route exact path="/" render={() => <Redirect to="/onboarding" />} />
    </IonRouterOutlet>
  );
};

export default OnboardingRoutes;
