/* eslint-disable no-extend-native */
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import ContentContainer from "../../components/common/ContentContainer";
import { useCallback, useEffect, useState } from "react";
import { EventRegister } from "react-native-event-listeners";
import { useLocation, withRouter } from "react-router";
import data from "../../assets/json/info.json";
import DOMPurify from "dompurify";
import "./Info.css";
import DataController from "../../controllers/DataController";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { TTSOptions, TextToSpeech } from "@capacitor-community/text-to-speech";
import { accessibilityAtom } from "../../state/State";
import { IAccessibilitySettings } from "../../Interfaces";
import { useRecoilValueLoadable } from "recoil";
import { Capacitor } from "@capacitor/core";

String.prototype.replaceAll = function (search: any, replacement: any) {
  var target = this;
  return target.split(search).join(replacement);
};

const Info: React.FC = (props) => {
  const location = useLocation();

  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);
  const [playing, setPlaying] = useState<boolean>(false);
  const [info, setInfo] = useState<any>(null);

  useEffect(() => {
    const getItem = (): void => {
      if (location.pathname) {
        const pathname = location.pathname;
        const slug = pathname.substring(pathname.lastIndexOf("/") + 1);
        const search = slug.replace(/-/gim, " ");

        const item = data.find((item, index) => item.Title.toLowerCase() === search);

        item && setInfo(item);
      }
    };

    getItem();
  }, [location]);

  useEffect(() => {
    return () => {
      TextToSpeech.stop();
    };
  }, []);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const handleClick = async (event: Event): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href) {
        event.preventDefault();
        window.open(element.href, "_blank");
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const sanitizeData = (data: string) => ({ __html: DOMPurify.sanitize(data) });

  const playPauseAudio = useCallback(async (): Promise<void> => {
    try {
      if (playing) {
        setPlaying(false);
        TextToSpeech.stop();
      } else {
        let content = document.getElementById("infoContainer");
        let supportedLanguages = await TextToSpeech.getSupportedLanguages();
        let text: string = content?.innerText || "";

        if (supportedLanguages.languages.length > 0) {
          text = text.replace(/\n/g, ". ");
          text = text.replace(/\.\./g, ".");
          text = text.replace(/\.\s\./g, ".");

          if (text.startsWith(". ")) {
            text = text.slice(2);
          }

          let textToSpeechProperties: TTSOptions = {
            text: text || "",
            rate: 1.0,
            pitch: 1.0,
            volume: 1.0,
            category: "playback",
          };

          if (supportedLanguages.languages.includes("en-GB")) {
            textToSpeechProperties.lang = "en-GB";
          }

          setPlaying(true);
          await TextToSpeech.speak(textToSpeechProperties);
          setPlaying(false);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [playing]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <IonTitle>{""}</IonTitle>
          {accessibilitySettings.contents?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="headerButton" mode="ios" onClick={() => playPauseAudio()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground whiteBackground infoPage">
        <ContentContainer tabBarDisabled id="infoContainer">
          {info && (
            <>
              <div className="infoPageTitle">{info.Title}</div>
              <div dangerouslySetInnerHTML={sanitizeData(info.HTML)} />
            </>
          )}
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Info);
