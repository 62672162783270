import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonFooter,
  IonHeader,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonTitle,
  IonToolbar,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewDidLeave,
  withIonLifeCycle,
} from "@ionic/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button_Checkbox_Active,
  Button_Checkbox_Inactive,
  Button_Error,
  Button_Onboarding_Tick,
  Button_Reflections_Tick,
  Icon_Attachment_Onboarding,
} from "../../assets/images";
import {
  AWSAccessParams,
  IAppAccess,
  IAppFeatures,
  ICertificateInfo,
  IClassSubscription,
  ILoginModal,
  ISubscriptionModalType,
  IUser,
  OnboardingCertificateIssueDate,
} from "../../Interfaces";
import validator from "validator";

import "../../components/onboarding/LoginModal.css";
import { useRecoilState } from "recoil";
import { appFeaturesAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { useHistory, withRouter } from "react-router";
import roles from "../../assets/json/roles.json";
import roleCertificates from "../../assets/json/role-certificates.json";
import { addDays, endOfDay, format, isAfter, isBefore, isSameDay, parseISO } from "date-fns";
import { FileContent, useFilePicker } from "use-file-picker";
import AWSService from "../../controllers/AWSService";
import { DatabaseService } from "../../controllers/DatabaseService";
import _ from "lodash";
import DataController from "../../controllers/DataController";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { FirebaseService } from "../../controllers/FirebaseService";
import RemoteDataController from "../../controllers/RemoteDataController";
import LearnMoreModal from "../../components/onboarding/LearnMoreModal";
import AuthenticationController from "../../controllers/AuthenticationController";
import { EventRegister } from "react-native-event-listeners";
import { Capacitor } from "@capacitor/core";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import HeaderTitle from "../../components/common/HeaderTitle";
import { CLASS_TERMS_URL } from "../../Constants";

const Register: React.FC = () => {
  const firstNameRef = useRef<HTMLInputElement>(null);
  const lastNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const confirmEmailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const confirmPasswordRef = useRef<HTMLInputElement>(null);

  const history = useHistory();
  const router = useIonRouter();

  const [_recoilUser, setUser] = useRecoilState(userAtom);
  const [deviceInfo, setDeviceInfo] = useRecoilState<DeviceInfo | null>(deviceInfoAtom);
  const [appInfo, setAppInfo] = useRecoilState<AppInfo | null>(appInfoAtom);
  const [appFeatures, setAppFeatures] = useRecoilState<IAppFeatures[] | string[]>(appFeaturesAtom);

  // Register page
  const [isRegistering, setIsRegistering] = useState<boolean>(false);
  const [hasErrors, setHasErrors] = useState<boolean>(false);
  const [errors, setErrors] = useState<string>("");
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [confirmEmail, setConfirmEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [confirmPassword, setConfirmPassword] = useState<string>("");
  const [passwordHidden, setPasswordHidden] = useState<boolean>(true);

  const [confirmEmailFocused, setConfirmEmailFocused] = useState<boolean>(false);
  const [confirmPasswordFocused, setConfirmPasswordFocused] = useState<boolean>(false);
  const [passwordFocused, setPasswordFocused] = useState<boolean>(false);

  // Validation
  const [emailValid, setEmailValid] = useState<boolean>(false);
  const [passwordValid, setPasswordValid] = useState<boolean>(false);

  // Post login
  const [fullFreeAccess, setFullFreeAccess] = useState<boolean>(false);
  const [trialAccess, setTrialAccess] = useState<boolean>(false);

  // Modal
  const [learnMoreModalVisible, setLearnMoreModalVisible] = useState<boolean>(false);

  const handleClick = async (event: Event): Promise<void> => {
    const element = event.target as HTMLAnchorElement;

    if (element.href && element.href?.includes("registerContactSupport")) {
      event.preventDefault();
      await FirebaseService.logEvent("support_link_pressed", {
        link: "contact_support",
        type: "app_support",
      });

      window.open("mailto:apps@class.co.uk?subject=ParaFolio - App support", "_blank");
    } else if (element.href && element.href?.includes(CLASS_TERMS_URL)) {
      await FirebaseService.logEvent("support_link_pressed", {
        link: "terms_and_conditions",
      });
    } else if (
      element.href &&
      element.href.includes("mailto:C2-training@swast.nhs.uk?subject=ParaFolio - Data opt out")
    ) {
      await FirebaseService.logEvent("support_link_pressed", {
        link: "data_opt_out",
      });
    } else if (element.href && element.href?.includes("registerLearnMore")) {
      event.preventDefault();
      setLearnMoreModalVisible(true);
    } else if (
      element.href &&
      (element.href?.includes("registerLoginSupport") || element.href?.includes("classWebsite"))
    ) {
      event.preventDefault();
    } else if (element.href && element.href?.includes("registerGoToLogin")) {
      event.preventDefault();
      router.push("/onboarding/login", "forward", "replace");
    }
  };

  useIonViewDidEnter(() => {
    document.addEventListener("click", handleClick);
  });

  useIonViewDidLeave(() => {
    document.removeEventListener("click", handleClick);
  });

  useEffect(() => {
    const scrollToTop = (): void => {
      try {
        const element = document.getElementById(`registerContentContainer`);

        // @ts-ignore
        element?.scrollIntoView({ behavior: "instant" });
      } catch (error) {
        console.log(error);
      }
    };

    if (trialAccess) {
      scrollToTop();
    }
  }, [trialAccess]);

  useEffect(() => {
    const setNavbarColors = async (): Promise<void> => {
      if (Capacitor.getPlatform() === "android") {
        try {
          NavigationBar.setColor({ color: "#F7FAF8" });
        } catch (error) {}
      }
    };

    setNavbarColors();
  }, []);

  useEffect(() => {
    const checkPasswordValid = (): void => {
      let valid = false;

      valid = password.length > 0 && validatePassword(password);

      setPasswordValid(valid);
    };

    if (password.length > 0) {
      checkPasswordValid();
    }
  }, [password]);

  const resetModal = (): void => {
    setTimeout(() => {
      resetErrors();
      setEmail("");
      setPassword("");
      setPasswordHidden(true);
      setFirstName("");
      setLastName("");
      setConfirmEmail("");
      setConfirmPassword("");
      setPasswordHidden(false);
      setFullFreeAccess(false);
      setTrialAccess(false);
    }, 500);
  };

  const logout = async (): Promise<void> => {
    resetModal();
    await AuthenticationController.logout();
    setUser(null);
    history.goBack();
  };

  const setFirstScreenShown = async (user: IUser): Promise<void> => {
    try {
      const subscriptions = user.userData.subscriptions;
      const subscription = getLatestPortfolioSubscription(subscriptions);

      if (subscription) {
        const success = await AuthenticationController.updateSubAppData(
          user.userData.contactID,
          user.token!,
          subscription.SubscriberID
        );

        // console.log('setFirstScreenShow', success);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const setupUser = async (): Promise<void> => {
    const user = await AuthenticationController.getUser();

    if (user) {
      await setFirstScreenShown(user);
    }

    setTimeout(() => {
      setUser(user);
    }, 500);
  };

  const goToApp = async (): Promise<void> => {
    try {
      document.removeEventListener("click", handleClick);

      await setupUser();

      setTimeout(() => {
        history.replace("/");
      }, 500);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    EventRegister.addEventListener("subscription/continue", () => {
      goToApp();
    });

    return () => {
      EventRegister.removeEventListener("subscription/continue");
    };
  }, []);

  const getLatestPortfolioSubscription = (subscriptions: IClassSubscription[]): IClassSubscription | null => {
    const subscriptionIDs = DataController.getPortfolioSubscriptionIDs();

    let portfolioSubscriptions = subscriptions.filter((subscription) =>
      subscriptionIDs.includes(subscription.SubscriptionID)
    );
    let latestSubscription = null;

    if (portfolioSubscriptions.length > 0) {
      portfolioSubscriptions = _.orderBy(portfolioSubscriptions, ["ExpiryDate"], ["desc"]);

      // Get non-expiring subscription
      latestSubscription = portfolioSubscriptions.find((item) => {
        const dateSeconds = parseInt(item.ExpiryDate.replace("/Date(", "").replace(")/", ""), 10);

        return dateSeconds === 0;
      });

      // No non-expiring subscription, get latest
      if (!latestSubscription) {
        latestSubscription = portfolioSubscriptions[0];
      }
    }

    return latestSubscription;
  };

  const getAllParaFolioSubscriptions = (subscriptions: IClassSubscription[]): IClassSubscription[] => {
    const subscriptionIDs = DataController.getPortfolioSubscriptionIDs();
    const portfolioSubscriptions = subscriptions.filter((subscription) =>
      subscriptionIDs.includes(subscription.SubscriptionID)
    );

    return portfolioSubscriptions;
  };

  const getAppFeaturesFromSubscriptions = (subscriptions: IClassSubscription[]): IAppFeatures[] | string[] => {
    const subscriptionIDs = _.uniq(subscriptions.map((subscription) => subscription.SubscriptionID));

    const appFeaturesArray: IAppFeatures[] | string[] =
      _.uniq(subscriptionIDs.map((id) => DataController.getAppFeaturesFromSubscriptionID(id))) || [];

    return appFeaturesArray;
  };

  const openSubscriptionOptions = async (): Promise<void> => {
    EventRegister.emit("subscriptions/show-modal", ISubscriptionModalType.ONBOARDING);
  };

  const getAppAccessType = (subscription: IClassSubscription): string => {
    return DataController.getAccessTypeFromSubscriptionID(subscription.SubscriptionID);
  };

  const showAccessType = (accessType: string): void => {
    if (accessType === IAppAccess.GROUP) {
      setFullFreeAccess(true);
    } else if (accessType === IAppAccess.INDIVIDUAL) {
      setTrialAccess(true);
    } else if (accessType === IAppAccess.SWAST) {
      // TODO: SWAST access
    }
  };

  const closeLogin = (): void => {
    goToApp();
  };

  const register = async (): Promise<void> => {
    resetErrors();
    setIsRegistering(true);

    let features: string[] = [IAppFeatures.GENERAL];
    let latestSubscription: IClassSubscription | null = null;

    try {
      let user = await AuthenticationController.register(firstName, lastName, email, password);

      if (user) {
        user = await AuthenticationController.login(email, password);

        if (user) {
          const subscriptions = user.userData.subscriptions;

          if (subscriptions && subscriptions.length > 0) {
            latestSubscription = getLatestPortfolioSubscription(subscriptions);

            if (latestSubscription) {
              const allParaFolioSubscriptions = getAllParaFolioSubscriptions(subscriptions);
              features = getAppFeaturesFromSubscriptions(allParaFolioSubscriptions);

              // Get correct app features from subscriptions
              setAppFeatures(features);
              await AuthenticationController.saveAppFeatures(features);
            } else {
              throw Error("Registered but failed to create subscription");
            }
          }
        } else {
          throw Error("Registered but failed to login");
        }

        let userDetails = await DatabaseService.getUserDetails(user);

        if (!userDetails) {
          await DatabaseService.addUserDetails(user, {});
          userDetails = await DatabaseService.getUserDetails(user);
        }

        if (
          (userDetails && userDetails.contactID === `${user.userData.contactID}`) ||
          features.includes(IAppFeatures.GENERAL)
        ) {
          if (userDetails.role) {
            user.role = userDetails.role;
          }

          if (userDetails.dataSharing) {
            user.usageData = userDetails.dataSharing === 1;
          }

          if (userDetails.certificates) {
            user.certificates = userDetails.certificates && JSON.parse(userDetails.certificates);
          }

          if (userDetails.personalStatement) {
            user.personalStatement = userDetails.personalStatement;
          }

          if (userDetails.summaryOfWork) {
            user.summaryOfWork = userDetails.summaryOfWork;
          }

          if (userDetails.personalStatementTimestamp) {
            user.personalStatementTimestamp = userDetails.personalStatementTimestamp;
          }

          if (userDetails.summaryOfWorkTimestamp) {
            user.summaryOfWorkTimestamp = userDetails.summaryOfWorkTimestamp;
          }

          if (userDetails.HCPCNumber) {
            user.HCPCNumber = userDetails.HCPCNumber;
          }

          if (userDetails.programCertificates) {
            user.programCertificates = userDetails.programCertificates && JSON.parse(userDetails.programCertificates);
          }

          if (userDetails.programRoles) {
            user.programRoles = userDetails.programRoles && JSON.parse(userDetails.programRoles);
          }

          await AuthenticationController.saveUser(user);

          const accessType = getAppAccessType(latestSubscription!);
          showAccessType(accessType);
        }
        // login(email, password);
      }

      setIsRegistering(false);
    } catch (error: string | any) {
      setIsRegistering(false);
      setHasErrors(true);
      setErrors(error + "");
    }
  };

  const registerClicked = (): void => {
    register();
  };

  const validateRegisterDetails = (): boolean => {
    return validateEmail(email) && password.length > 0;
  };

  const validateFirstName = (value: string): boolean => {
    return validator.isAlpha(value, undefined, { ignore: " ,.'-" });
    // return validator.isAlpha(value, 'en-GB', { ignore: " ,.'-" });
  };

  const validateLastName = (value: string): boolean => {
    return validator.isAlpha(value, undefined, { ignore: " ,.'-" });
    // return validator.isAlpha(value, 'en-GB', { ignore: " ,.'-" });
  };

  const validateEmail = (value: string): boolean => {
    return validator.isEmail(value);
  };

  const validateConfirmEmail = (): boolean => {
    if (email.length > 0 && confirmEmail.length > 0) {
      return email === confirmEmail;
    }

    return false;
  };

  const validatePassword = (value: string): boolean => {
    return validator.isStrongPassword(value, {
      minLength: 12,
      minLowercase: 1,
      minUppercase: 1,
      minNumbers: 1,
      minSymbols: 0,
    });
  };

  const validatePasswordLength = (value: string): boolean => {
    return validator.isStrongPassword(value, {
      minLength: 12,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validatePasswordLowercase = (value: string): boolean => {
    return validator.isStrongPassword(value, {
      minLength: 1,
      minLowercase: 1,
      minUppercase: 0,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validatePasswordUppercase = (value: string): boolean => {
    return validator.isStrongPassword(value, {
      minLength: 1,
      minLowercase: 0,
      minUppercase: 1,
      minNumbers: 0,
      minSymbols: 0,
    });
  };

  const validatePasswordNumbers = (value: string): boolean => {
    return validator.isStrongPassword(value, {
      minLength: 1,
      minLowercase: 0,
      minUppercase: 0,
      minNumbers: 1,
      minSymbols: 0,
    });
  };

  const validateConfirmPassword = (): boolean => {
    if (password.length > 0 && confirmPassword.length > 0) {
      return password === confirmPassword;
    }

    return false;
  };

  const resetErrors = (): void => {
    setHasErrors(false);
    setErrors("");
  };

  const firstNameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 0) {
      validateFirstName(event.target.value) && setFirstName(event.target.value);
    } else {
      setFirstName("");
    }
  };

  const LastNameChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (event.target.value.length > 0) {
      validateLastName(event.target.value) && setLastName(event.target.value);
    } else {
      setLastName("");
    }
  };

  const emailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(event.target.value);
  };

  const confirmEmailChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmEmail(event.target.value);
  };

  const passwordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(event.target.value);
  };

  const confirmPasswordChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setConfirmPassword(event.target.value);
  };

  const firstNameKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      lastNameRef.current?.focus();
    }
  };

  const lastNameKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      emailRef.current?.focus();
    }
  };

  const emailKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      // if (!isRegistering && validateRegisterDetails()) {
      // register();
      // } else {
      confirmEmailRef.current?.focus();
      // }
    } else if (event.key === " ") {
      event.preventDefault();
    }
  };

  const confirmEmailKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      passwordRef.current?.focus();
    } else if (event.key === " ") {
      event.preventDefault();
    }
  };

  const passwordKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      // !isRegistering && validateRegisterDetails() && register();
      confirmPasswordRef.current?.focus();
    }
  };

  const confirmPasswordKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      if (!isRegistering && validateRegisterDetails()) {
        register();
      }
    }
  };

  const registerEnabled = (): boolean => {
    return (
      validateFirstName(firstName) &&
      validateLastName(lastName) &&
      validateEmail(email) &&
      validateConfirmEmail() &&
      validatePassword(password) &&
      validateConfirmPassword()
    );
  };

  const toggleModalVisible = (value: boolean): void => {
    setLearnMoreModalVisible(value);
  };

  return (
    <IonPage>
      <LearnMoreModal
        page="Register"
        visible={learnMoreModalVisible}
        closeModal={(value: boolean) => toggleModalVisible(value)}
      />
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <HeaderTitle>{"ParaFolio"}</HeaderTitle>
          <IonButtons slot="start">
            {trialAccess ? (
              <IonButton mode="ios" className="cancelButton" onClick={() => closeLogin()}>
                {"Close"}
              </IonButton>
            ) : (
              <IonBackButton
                className="headerBackButtons"
                defaultHref="/onboarding"
                text={DataController.getBackIconText()}
                icon={DataController.getBackIconType()}
                style={{
                  marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                  "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
                }}
              />
            )}
          </IonButtons>
          {trialAccess && (
            <IonButtons slot="end">
              <IonButton mode="ios" className="cancelButton" onClick={() => logout()}>
                {"Log out"}
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="onboardingLoginModalPage fullHeight">
        {trialAccess && (
          <>
            <div className="onboardingLoginContent" id="registerContentContainer">
              <div className="onboardingLoginHeader">{"Explore ParaFolio with a 30 day trial"}</div>
              <div className="onboardingLoginSubheader">
                {"The email you entered ("}
                <b>{email}</b>
                {") gets a "}
                <b>{"30 day trial"}</b>
                {" to ParaFolio. Get started with your portfolio now."}
              </div>
              <div className="onboardingFeaturesHeader">{"What ParaFolio offers:"}</div>
              <div className="onboardingFeaturesTickContainer">
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Track added evidence and hours spent doing CPD with "}
                    <b>{"Dashboard"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"See your activity from ParaPass and JRCALC Plus with "}
                    <b>{"Timeline"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Manage your CPD with audit tagging and custom tags in  "}
                    <b>{"Evidence"}</b>
                    {"."}
                  </div>
                </div>
                <div className="onboardingFeaturesTick">
                  <img className="onboardingFeaturesTickImage" src={Button_Reflections_Tick} alt="Tick icon" />
                  <div className="onboardingFeaturesTickText">
                    {"Keep on top of your portfolio and be ready for audit with your "}
                    <b>{"Profile"}</b>
                    {"."}
                  </div>
                </div>
              </div>
              <div className="loginButtonContainer" style={{ marginTop: -3.5 }}>
                <IonButton mode="ios" onClick={() => goToApp()} className="loginButton">
                  <div>{"Get started"}</div>
                </IonButton>
                <IonButton
                  mode="ios"
                  style={{ marginTop: 12 }}
                  fill="outline"
                  onClick={() => openSubscriptionOptions()}
                  className="loginButtonClear"
                >
                  <div>{"See subscription options"}</div>
                </IonButton>
              </div>
              <div className="loginFooter">
                <div className="loginFooterText">
                  {"Need assistance? "}
                  <a href="loginContactSupport">{"Contact support"}</a>
                </div>
                <div className="loginFooterText">
                  {"Please read our "}
                  <a href="loginPrivacyPolicy">{"Privacy Policy"}</a>
                  {" & "}
                  <a href="loginTermsAndConditions">{"Terms & Conditions"}</a>
                </div>
              </div>
            </div>
          </>
        )}
        {!trialAccess && (
          <div className="onboardingLoginContent">
            <div className="onboardingLoginHeader">{"Register and get your portfolio started today"}</div>
            <div className="onboardingLoginSubheader">
              {
                "Please be aware your activity from JRCALC Plus, iCPG, Responder Plus or ParaPass is only available when logging in using your login for those apps. "
              }
              <a className="onboardingLoginLink" href="registerLearnMore">
                {"Learn more"}
              </a>
            </div>
            <br />
            <div className="onboardingLoginSubheader">
              {"Already have an account? "}
              <a className="onboardingLoginLink" href="registerGoToLogin">
                {"Log in here"}
              </a>
            </div>
            <form>
              <div className="loginInputHeader">First name</div>
              <div className="loginInputContainer">
                <input
                  ref={firstNameRef}
                  className={`loginInput loginEmailInput ${hasErrors && "loginInputError"}`}
                  placeholder="What's your first name?"
                  id="loginNameInput"
                  type="text"
                  value={firstName}
                  autoComplete=""
                  onChange={firstNameChanged}
                  onFocus={resetErrors}
                  onKeyDown={firstNameKeyPressed}
                  // onKeyUp={firstNameKeyPressed}
                />
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
              </div>
              <div className="loginInputHeader">Surname</div>
              <div className="loginInputContainer">
                <input
                  ref={lastNameRef}
                  className={`loginInput loginEmailInput ${hasErrors && "loginInputError"}`}
                  placeholder="What's your surname?"
                  id="loginSurnameInput"
                  type="text"
                  value={lastName}
                  autoComplete=""
                  onChange={LastNameChanged}
                  onFocus={resetErrors}
                  onKeyDown={lastNameKeyPressed}
                  // onKeyUp={lastNameKeyPressed}
                />
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
              </div>
              <div className="loginInputHeader">Email address</div>
              <div className="loginInputContainer">
                <input
                  ref={emailRef}
                  className={`loginInput loginEmailInput ${hasErrors && "loginInputError"}`}
                  placeholder="Your email address"
                  id="loginEmailInput"
                  type="email"
                  value={email}
                  autoComplete="email"
                  onChange={emailChanged}
                  onFocus={() => {
                    setPasswordFocused(true);
                    resetErrors();
                  }}
                  onBlur={() => setPasswordFocused(false)}
                  onKeyDown={emailKeyPressed}
                  // onKeyUp={emailKeyPressed}
                />
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
              </div>
              <div className="loginInputHeader">Confirm email address</div>
              <div className="loginInputContainer">
                <input
                  ref={confirmEmailRef}
                  className={`loginInput loginEmailInput ${(hasErrors || (confirmEmail.length > 0 && !validateConfirmEmail())) && "loginInputError"}`}
                  placeholder="Confirm email address"
                  id="loginConfirmEmailInput"
                  type="email"
                  value={confirmEmail}
                  autoComplete="email"
                  onChange={confirmEmailChanged}
                  onFocus={() => {
                    setConfirmEmailFocused(true);
                    resetErrors();
                  }}
                  onBlur={() => setConfirmEmailFocused(false)}
                  onKeyDown={confirmEmailKeyPressed}
                  // onKeyUp={confirmEmailKeyPressed}
                />
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
                {confirmEmail.length > 0 && !validateConfirmEmail() && (
                  <div className="loginErrorContainerInline">
                    <div className="loginErrorMessageTextInline">{"Emails do not match"}</div>
                  </div>
                )}
              </div>
              <div className="loginInputHeader">Set a password</div>
              <div className="loginInputContainer">
                <input
                  ref={passwordRef}
                  className={`loginInput loginPasswordInput ${(hasErrors || (password.length > 0 && !validatePassword(password))) && "loginPasswordInputError"}`}
                  placeholder="Set a password"
                  type={passwordHidden ? "password" : "text"}
                  value={password}
                  autoComplete="current-password"
                  onChange={passwordChanged}
                  onFocus={() => {
                    setPasswordFocused(true);
                    resetErrors();
                  }}
                  onBlur={() => setPasswordFocused(false)}
                  onKeyDown={passwordKeyPressed}
                  // onKeyUp={passwordKeyPressed}
                />
                <div
                  className={`loginPasswordHidden ${hasErrors && "loginPasswordHiddenErrors"}`}
                  onClick={() => {
                    setPasswordHidden(!passwordHidden);
                    passwordRef.current?.focus();
                  }}
                >
                  {passwordHidden ? "Show" : "Hide"}
                </div>
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
                {/* {1 === 1 && ( */}
                {password.length > 0 && !validatePassword(password) && (
                  <div className="loginErrorContainerInline">
                    <div className="loginErrorMessageTextInline">{"Password is not valid"}</div>
                  </div>
                )}
                <div className="onboardingLoginSubheader" style={{ marginTop: 10 }}>
                  <div className="onboardingPasswordRequirementsContainer">
                    <b>{"Password requirements:"}</b>
                  </div>
                  <div className="onboardingPasswordRequirementsContainer">
                    {validatePasswordLength(password) && (
                      <img
                        className="onboardingPasswordRequirementsTick"
                        src={Button_Reflections_Tick}
                        alt="Tick icon"
                      />
                    )}
                    {"Min. 12 characters"}
                  </div>
                  <div className="onboardingPasswordRequirementsContainer">
                    {validatePasswordUppercase(password) && (
                      <img
                        className="onboardingPasswordRequirementsTick"
                        src={Button_Reflections_Tick}
                        alt="Tick icon"
                      />
                    )}
                    {"1 uppercase letter"}
                  </div>
                  <div className="onboardingPasswordRequirementsContainer">
                    {validatePasswordLowercase(password) && (
                      <img
                        className="onboardingPasswordRequirementsTick"
                        src={Button_Reflections_Tick}
                        alt="Tick icon"
                      />
                    )}
                    {"1 lowercase letter"}
                  </div>
                  <div className="onboardingPasswordRequirementsContainer">
                    {validatePasswordNumbers(password) && (
                      <img
                        className="onboardingPasswordRequirementsTick"
                        src={Button_Reflections_Tick}
                        alt="Tick icon"
                      />
                    )}
                    {"1 number"}
                  </div>
                </div>
              </div>
              <div className="loginInputHeader">Confirm your password</div>
              <div className="loginInputContainer">
                <input
                  ref={confirmPasswordRef}
                  className={`loginInput loginPasswordInput ${(hasErrors || (confirmPassword.length > 0 && !validateConfirmPassword())) && "loginPasswordInputError"}`}
                  placeholder="Confirm password"
                  type={passwordHidden ? "password" : "text"}
                  value={confirmPassword}
                  autoComplete="current-password"
                  onChange={confirmPasswordChanged}
                  onFocus={() => {
                    setConfirmPasswordFocused(true);
                    resetErrors();
                  }}
                  onBlur={() => setConfirmPasswordFocused(false)}
                  onKeyDown={confirmPasswordKeyPressed}
                  // onKeyUp={confirmPasswordKeyPressed}
                />
                <div
                  className={`loginPasswordHidden ${hasErrors && "loginPasswordHiddenErrors"}`}
                  onClick={() => {
                    setPasswordHidden(!passwordHidden);
                    confirmPasswordRef.current?.focus();
                  }}
                >
                  {passwordHidden ? "Show" : "Hide"}
                </div>
                {hasErrors && <img src={Button_Error} className="loginInputErrorIcon" alt="Alert icon" />}
                {confirmPassword.length > 0 && !validateConfirmPassword() && (
                  <div className="loginErrorContainerInline">
                    <div className="loginErrorMessageTextInline">{"Passwords do not match"}</div>
                  </div>
                )}
              </div>
              <div className="loginButtonContainer">
                <IonButton
                  mode="ios"
                  onClick={() => registerClicked()}
                  disabled={!registerEnabled()}
                  className={`loginButton ${!registerEnabled() ? "loginButtonDisabled" : ""}`}
                >
                  {isRegistering ? <IonSpinner /> : <div>{"Create account"}</div>}
                </IonButton>
              </div>
              {hasErrors && (
                <div className="loginErrorContainer" style={{ marginBottom: 24 }}>
                  <img src={Button_Error} className="loginErrorIcon" alt="Alert icon" />
                  <div className="loginErrorMessageText">
                    {errors.includes("already in use") ? (
                      <>
                        {"This email is already in use, try "}
                        <a className="onboardingLoginLinkError" href="registerGoToLogin">
                          {"logging in here"}
                        </a>
                      </>
                    ) : (
                      <>{errors}</>
                    )}
                  </div>
                </div>
              )}
            </form>
            <div className="loginFooter">
              <div className="loginFooterText">
                {"Need assistance? "}
                <a href="registerContactSupport">{"Contact support"}</a>
              </div>
              <div className="loginFooterText">
                {"Please read our "}
                <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer">
                  {"Privacy Policy"}
                </a>
                {" & "}
                <a href={CLASS_TERMS_URL} target="_blank" rel="noreferrer">
                  {"Terms & Conditions"}
                </a>{" "}
              </div>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Register);
