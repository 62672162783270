import { useRef, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import "./EvidenceParts.css";
import { Editor } from "@tinymce/tinymce-react";
import { Editor as TinyMCEEditor } from "tinymce";
import DOMPurify from "dompurify";
import { Text, View } from "@react-pdf/renderer";
import { Html } from "react-pdf-html";
import DataController from "../../../controllers/DataController";
import * as EditorUtils from "../../../utils/editorUtils";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any, isInput?: boolean) => void;
  focusRichText: () => void;
  blurRichText: () => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
  id?: any;
};

const RichTextBox: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  focusRichText,
  blurRichText,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
  id,
}) => {
  const ref = useRef<TinyMCEEditor | null>(null);

  const [value, setValue] = useState<string>(initialValue || "");

  const valueChanged = (change: any): void => {
    setValue(change);

    onValueChanged && onValueChanged(change, true);
  };

  const sanitizeData = (data: string) => ({ __html: DOMPurify.sanitize(data) });

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{(definition["Display Title"] || definition.Name) + ":"}</Text>
        <Html style={{ fontSize: 12 }} stylesheet={htmlStyles}>
          {DataController.decodeHTML(data)}
        </Html>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText" dangerouslySetInnerHTML={sanitizeData(data)} />
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <Editor
        tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
        id={id}
        onInit={(event, editor) => (ref.current = editor)}
        value={value}
        onEditorChange={(a, editor) => {
          a.length > 0 && editor.getBody().setAttribute("data-mce-placeholder", "");
          valueChanged(a);
        }}
        onFocus={() => focusRichText()}
        onBlur={() => blurRichText()}
        init={{
          height: EditorUtils.getInitialHeight(),
          placeholder:
            definition?.["Placeholder Text"] || "Add your comments or reflection on this enhanced skill here...",
          browser_spellcheck: true,
          branding: false,
          menubar: false,
          paste_data_images: false,
          content_style: "body {font-size: 14pt;}",
          plugins: ["autolink", "wordcount", "lists"],
          invalid_styles: {
            "*": "color line-height mso-outline-level background font-family",
          },
          style_formats: [
            {
              title: "Headings",
              items: [
                { title: "Heading 1", block: "h1" },
                { title: "Heading 2", block: "h2" },
                { title: "Heading 3", block: "h3" },
              ],
            },
            {
              title: "Inline",
              items: [
                { title: "Bold", block: "strong" },
                { title: "Italic", block: "em" },
                { title: "Underline", block: "u" },
                { title: "Strikethrough", block: "s" },
              ],
            },
            {
              title: "Blocks",
              items: [
                { title: "Paragraph", block: "p" },
                { title: "Blockquote", block: "blockquote" },
              ],
            },
          ],
          fontsize_formats: "8pt 10pt 12pt 14pt 18pt 24pt 36pt",
          toolbar: [
            "undo redo | styles fontsize | bold italic underline | bullist numlist | alignleft aligncenter alignright",
          ],
          mobile: {
            toolbar_mode: "sliding",
          },
        }}
      />
    </div>
  );
};

const htmlStyles = {
  p: {
    fontFamily: "Inter",
    marginTop: 8,
    marginBottom: 8,
  },
  b: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
  i: {
    fontFamily: "Inter",
    fontStyle: "italic",
  },
  em: {
    fontFamily: "Inter",
    fontStyle: "italic",
  },
  strong: {
    fontFamily: "Inter",
    fontWeight: 700,
  },
};

export default RichTextBox;
