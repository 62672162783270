import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonDatetime,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonPopover,
  IonRadio,
  IonRadioGroup,
  IonSpinner,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { format, isSameDay } from "date-fns";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { useRecoilState } from "recoil";
import { useFilePicker } from "use-file-picker";
import { Button_Checkbox_Active, Button_Checkbox_Inactive, Icon_Attachment_Onboarding } from "../../assets/images";
import roles from "../../assets/json/roles.json";
import roleCertificates from "../../assets/json/role-certificates.json";
import AuthenticationController from "../../controllers/AuthenticationController";
import AWSService from "../../controllers/AWSService";
import {
  AWSAccessParams,
  IAppFeatures,
  ICertificateInfo,
  IUser,
  IUserCertificate,
  OnboardingCertificateIssueDate,
} from "../../Interfaces";
import { appFeaturesAtom, userAtom } from "../../state/State";
import _ from "lodash";
import validator from "validator";
import "./EditRole.css";
import { DatabaseService } from "../../controllers/DatabaseService";
import { EventRegister } from "react-native-event-listeners";
import { FirebaseService } from "../../controllers/FirebaseService";
import RemoteDataController from "../../controllers/RemoteDataController";
import DataController from "../../controllers/DataController";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import { CERTIFICATE_ACCEPTED_FILE_TYPES, CERTIFICATE_MAX_FILE_SIZE } from "../../Constants";

const EditRole: React.FC = (props) => {
  const [openFileSelector, { filesContent, clear, plainFiles, errors }] = useFilePicker({
    accept: CERTIFICATE_ACCEPTED_FILE_TYPES,
    maxFileSize: CERTIFICATE_MAX_FILE_SIZE,
    readAs: "DataURL",
    multiple: false,
    limitFilesConfig: { max: 1 },
  });

  const HCPCInputRef = useRef<HTMLInputElement>(null);

  const [user, setUser] = useRecoilState<IUser | null>(userAtom);
  const [appFeatures, setAppFeatures] = useRecoilState<IAppFeatures[] | string[]>(appFeaturesAtom);

  const [role, setRole] = useState<string>(user?.role || "");
  const [HCPCNumber, setHCPCNumber] = useState<string>(user?.HCPCNumber || "");
  const [certificatesForRole, setCertificatesForRole] = useState<any[]>([]);
  const [certificateType, setCertificateType] = useState<string>("");
  const [certificate, setCertificate] = useState<IUserCertificate[]>(user?.certificates || []);
  const [hasCertificate, setHasCertificate] = useState<boolean>(false);
  const [dates, setDates] = useState<OnboardingCertificateIssueDate[]>([]);
  const [datePickersVisible, setDatePickersVisible] = useState<boolean[]>([]);
  const [optIn, setOptIn] = useState<boolean>(user?.usageData || false);

  const [upload, setUpload] = useState<ICertificateInfo | null>(null);
  const [uploadingCertificate, setUploadingCertificate] = useState(false);
  const [deletingCertificate, setDeletingCertificate] = useState(false);
  const [newDate, setNewDate] = useState<Date | null>(null);
  const [changingDate, setChangingDate] = useState<boolean>(false);

  const [savingHCPCNumber, setSavingHCPCNumber] = useState<boolean>(false);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("edit");
    };

    setPageName();
  }, []);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const uploadCertificate = async (): Promise<void> => {
      try {
        if (user && upload) {
          const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user);

          let response = await AWSService.uploadCertificate(user, accessParams, upload);

          if (response && response.networkIssue) {
            response = await AWSService.uploadCertificateFromServer(user, accessParams, upload);
          }

          if (response) {
            const userToSave = _.cloneDeep(user);
            const _certificates = _.cloneDeep(userToSave.certificates) || [];

            const _certificate: IUserCertificate = {
              certificate: {
                ...upload,
                content: undefined,
                url: response.Location,
              },
              issueDate: dates[0].date.toISOString(),
            };

            const index = _certificates?.findIndex(
              (item) => item.certificate?.certificateType === _certificate.certificate?.certificateType
            );

            if (index > -1) {
              _certificates.splice(index, 1, _certificate);
            } else {
              _certificates.push(_certificate);
            }

            userToSave.certificates = _certificates;
            // console.log('userToSave', userToSave);

            await DatabaseService.updateUserDetails(userToSave, {
              certificates: userToSave.certificates,
            });

            setUpload(null);
            setUser(userToSave);
            setHasCertificate(true);
            setUploadingCertificate(false);
            setCertificateType("");
          }
        }
      } catch (error: any) {
        console.log(error);
        RemoteDataController.logError(error);
        setUpload(null);
        setUploadingCertificate(false);
        setCertificateType("");
        setTimeout(() => {
          window.alert("Failed to add certificate. Please try again and check the file isn't corrupted");
        }, 100);
      }
    };

    if (user && upload) {
      uploadCertificate();
    }
  }, [clear, dates, setUser, upload, user]);

  useEffect(() => {
    if (plainFiles.length > 0) {
      setUploadingCertificate(true);

      const _certificate: ICertificateInfo = {
        name: plainFiles[0].name,
        type: plainFiles[0].type,
        size: plainFiles[0].size,
        content: filesContent[0].content,
        lastModified: plainFiles[0].lastModified,
        certificateType,
      };

      // console.log(_certificate);
      setUpload(_certificate);
      clear();
    }
  }, [certificateType, clear, filesContent, plainFiles]);

  useEffect(() => {
    if (errors.length) {
      setUploadingCertificate(false);
    }
  }, [errors]);

  useEffect(() => {
    let _role = roles.find((item) => item.Name === role);

    if (_role) {
      let _certificates = roleCertificates.filter((item) => item.Roles.includes(_role?.id || ""));

      setCertificatesForRole(_certificates);
      setDates(
        Array.from({ length: _certificates.length }, (item, index) => ({
          date: new Date(),
          type: _certificates[index].Name,
        }))
      );
    }
  }, [role]);

  useEffect(() => {
    if (user && user.certificates) {
      setCertificate(user.certificates);

      const _dates = _.cloneDeep(dates);

      // console.log(_dates, certificatesForRole);

      if (_dates.length !== certificatesForRole.length) {
        for (let i = 0; i < user.certificates.length; i++) {
          const date = _dates.find(
            (item) => user.certificates && item.type === user.certificates[i].certificate?.certificateType
          );
          const dateIndex = _dates.findIndex((item) => item.type === date?.type);

          if (date && dateIndex) {
            _dates.splice(dateIndex, 1, {
              date: new Date(user.certificates[i].issueDate),
              type: date.type,
            });
          }
        }

        // console.log(_dates);
        setDates(_dates);
      }
    }

    if (user?.role) {
      setRole(user.role);
    }
  }, [certificatesForRole, dates, user]);

  useEffect(() => {
    const changeIssueDate = async (): Promise<void> => {
      try {
        if (user && changingDate && newDate) {
          const userToSave = _.cloneDeep(user);
          const _certificates = _.cloneDeep(userToSave.certificates) || [];

          const index = _certificates?.findIndex((item) => item.certificate?.certificateType === certificateType);

          if (index > -1) {
            const _certificate: IUserCertificate = {
              certificate: _certificates[index].certificate,
              issueDate: newDate.toISOString(),
            };

            _certificates.splice(index, 1, _certificate);
          }

          userToSave.certificates = _certificates;
          // console.log('userToSave', userToSave);

          await DatabaseService.updateUserDetails(userToSave, {
            certificates: userToSave.certificates,
          });

          setNewDate(null);
          setHasCertificate(true);
          setChangingDate(false);
          setCertificateType("");
          setTimeout(() => {
            setUser(userToSave);
          }, 100);
        }
      } catch (error) {
        console.log(error);
        setChangingDate(false);
        setNewDate(null);
        setCertificateType("");
      }
    };

    if (user && changingDate) {
      changeIssueDate();
    }
  }, [certificateType, changingDate, newDate, setUser, user]);

  const editUserRole = async (value: string): Promise<void> => {
    // console.log(value);
    if (role !== value) {
      try {
        let userToSave: IUser = { ...user };

        if (userToSave) {
          userToSave.role = value;

          const updated = await DatabaseService.updateUserDetails(userToSave, {
            role: value,
          });

          setUser(userToSave);
          setRole(value);
        }
      } catch (error) {
        // console.log(error);

        window.alert("Failed to update role. Please try again");
        // console.log(role);

        let element = document.getElementById("editRoleRadioGroup");
        // console.log(element);
      }
    }
  };

  const openDatePicker = (index: number): void => {
    setDatePickersVisible(Array.from({ length: certificatesForRole.length }, (item, i) => i === index));
  };

  const closeDatePicker = useCallback(
    (index: number): void => {
      setDatePickersVisible(Array.from({ length: certificatesForRole.length }, (item, i) => false));
    },
    [certificatesForRole.length]
  );

  const changeDate = useCallback(
    (event: any, type: string, initialDate: Date): void => {
      try {
        const isSameDate = isSameDay(new Date(event.detail.value), initialDate);

        if (newDate === null && !isSameDate) {
          setCertificateType(type);
          setChangingDate(true);
          setNewDate(new Date(event.detail.value));
        }
      } catch (error) {
        console.log(error);
        window.alert("Failed to change issue date. Please try again");
      }
    },
    [newDate]
  );

  const addCertificate = async (type: string): Promise<void> => {
    try {
      setCertificateType(type);
      setUploadingCertificate(true);
      openFileSelector();
    } catch (error) {
      console.log(error);
      window.alert("Failed to add certificate. Please try again");
    }
  };

  const removeCertificate = async (type: string): Promise<void> => {
    try {
      setDeletingCertificate(true);
      setCertificateType(type);

      let _certificates = _.cloneDeep(certificate);
      let _certificateToDelete = _certificates.find((item) => item.certificate?.certificateType === type);

      if (_certificateToDelete && _certificateToDelete.certificate) {
        let deleted = await deleteCertificate(_certificateToDelete.certificate);

        if (deleted) {
          _certificates = _certificates.filter((item) => item.certificate?.certificateType !== type);
          // console.log(_certificates);

          let userToSave: IUser = { ...user };

          if (userToSave) {
            userToSave.certificates = _certificates;

            const updated = await DatabaseService.updateUserDetails(userToSave, { certificates: _certificates });

            // console.log(updated);
            setUser(userToSave);
            setCertificate(_certificates);
          }
        } else {
          window.alert("Failed to delete certificate. Please try again");
        }
      }

      setDeletingCertificate(false);
      setCertificateType("");
    } catch (error) {
      setDeletingCertificate(false);
      setCertificateType("");

      console.log(error);
      window.alert("Failed to delete certificate. Please try again");
    }
  };

  const deleteCertificate = async (_certificate: ICertificateInfo | undefined): Promise<boolean | void> => {
    const accessParams: AWSAccessParams | null = user && (await AWSService.generateSTSToken(user));

    if (user && accessParams) {
      const fileDeleted = await AWSService.deleteCertificate(user, accessParams, _certificate);
      return fileDeleted;
    }
  };

  const validateHCPCNumber = (number: string): boolean => {
    return validator.matches(number, /^[a-zA-Z0-9]{0,20}$/gim);
  };

  const HCPCKeyPressed = (event: React.KeyboardEvent): void => {
    if (event.key === "Enter") {
      if (!savingHCPCNumber && HCPCNumber !== user?.HCPCNumber && HCPCNumber !== "") {
        event.preventDefault();

        saveHCPCNumber();
      }
    }
  };

  const HCPCNumberChanged = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (validateHCPCNumber(event.target.value)) {
      setHCPCNumber(event.target.value.toUpperCase());
    } else {
      setHCPCNumber(
        event.target.value
          .toUpperCase()
          .replace(/[^a-zA-Z0-9]/gim, "")
          .substring(0, 20)
      );
    }
  };

  const saveHCPCNumber = async (): Promise<void> => {
    try {
      setSavingHCPCNumber(true);

      let userToSave: IUser = { ...user };

      if (userToSave) {
        userToSave.HCPCNumber = HCPCNumber;

        const updated = DatabaseService.updateUserDetails(userToSave, {
          HCPCNumber,
        });

        setUser(userToSave);

        setTimeout(() => {
          setSavingHCPCNumber(false);
        }, 1000);
      }
    } catch (error) {
      console.log(error);
      setSavingHCPCNumber(false);
      window.alert("Failed to update registration number. Please try again");
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref="/profile"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Edit profile"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground whiteBackground">
        <div className="editRolesContent">
          {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
            <>
              <div className="editRolesHeader">{"Role"}</div>
              <div className="onboardingRadioGroup">
                <IonRadioGroup
                  color="light"
                  id="editRoleRadioGroup"
                  value={role}
                  onIonChange={(event) => editUserRole(event.detail.value)}
                >
                  {roles.map((item, index) => {
                    return (
                      <IonItem className="onboardingRadioContainer" key={item.id}>
                        <IonLabel className="onboardingRadioLabel">{item.Name}</IonLabel>
                        <IonRadio aria-label={item.Name} mode="md" value={item.Name} slot="end" />
                      </IonItem>
                    );
                  })}
                </IonRadioGroup>
              </div>
            </>
          )}
          <div
            className="onboardingDateHeader"
            style={{
              marginTop: !appFeatures.includes(IAppFeatures.SWAST_MIC) ? 0 : undefined,
            }}
          >
            {"Registration number"}
          </div>
          <div style={{ position: "relative" }}>
            <input
              ref={HCPCInputRef}
              className="hcpcInputContainer"
              placeholder="Add your registration number..."
              autoComplete="off"
              autoCapitalize="characters"
              id="HCPCNumberInput"
              value={HCPCNumber}
              pattern="[a-zA-Z0-9]+"
              onChange={HCPCNumberChanged}
              onKeyUp={HCPCKeyPressed}
            />
            {savingHCPCNumber ? (
              <div className="saveHCPCButtonSpinnerContainer">
                <IonSpinner className="saveHCPCButtonSpinner" />
              </div>
            ) : (
              <button
                className="saveHCPCButton"
                disabled={HCPCNumber === user?.HCPCNumber || HCPCNumber === ""}
                onClick={() => saveHCPCNumber()}
              >
                {"Save"}
              </button>
            )}
          </div>
          {appFeatures.includes(IAppFeatures.SWAST_MIC) && (
            <>
              {certificatesForRole.map((item, index) => {
                const addedCertificate = certificate.find((cert) => cert.certificate?.certificateType === item.Name);
                const date = dates.find((_date) => _date.type === item.Name);

                // console.log(addedCertificate, date);

                return (
                  <div key={item.Name}>
                    <div className="onboardingDateHeader">{item.Name}</div>
                    {addedCertificate?.certificate && (
                      <>
                        <div className="onboardingAttachmentContainer" key={index}>
                          <img src={Icon_Attachment_Onboarding} className="onboardingAttachmentIcon" alt="" />
                          <div className="onboardingAttachmentFilename">{addedCertificate.certificate.name}</div>
                          {deletingCertificate && certificateType === addedCertificate.certificate.certificateType ? (
                            <div className="onboardingAttachmentCancelSpinnerContainer">
                              <IonSpinner className="onboardingAttachmentCancelSpinner" />
                            </div>
                          ) : (
                            <div
                              className="onboardingAttachmentCancel"
                              onClick={() => removeCertificate(addedCertificate?.certificate?.certificateType || "")}
                            >
                              {"Remove"}
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="onboardingDateHeader">{"Issue date"}</div>
                          <div
                            id={`onboardingDatePicker-${user?.role}-${item.Name}`}
                            className="onboardingDateContainer"
                            onClick={() => openDatePicker(index)}
                          >
                            <div className="onboardingDateText">
                              {format(new Date(addedCertificate?.issueDate) || new Date(), "dd / MM / yyyy")}
                            </div>
                            <IonPopover
                              trigger={`onboardingDatePicker-${user?.role}-${item.Name}`}
                              isOpen={datePickersVisible[index]}
                              onDidDismiss={() => closeDatePicker(index)}
                            >
                              <IonDatetime
                                mode="md"
                                size="cover"
                                showDefaultButtons={true}
                                doneText="Confirm"
                                value={new Date(addedCertificate?.issueDate).toISOString() || new Date().toISOString()}
                                onChange={(event) => console.log("DateTimeOnChange", event)}
                                onIonChange={(event) =>
                                  changeDate(event, item.Name, new Date(addedCertificate?.issueDate))
                                }
                                max={new Date().toISOString()}
                                presentation="date"
                                firstDayOfWeek={1}
                              />
                            </IonPopover>
                          </div>
                        </div>
                      </>
                    )}
                    <div className="loginButtonContainer" style={{ marginTop: 24 }}>
                      <IonButton
                        mode="ios"
                        fill="outline"
                        onClick={() => addCertificate(item.Name)}
                        className="addAttachmentButton"
                      >
                        <div>
                          {uploadingCertificate && certificateType === item.Name ? (
                            <IonSpinner className="onboardingAddAttachmentsSpinner" />
                          ) : (
                            <>{addedCertificate ? "Update certificate" : "Add certificate"}</>
                          )}
                        </div>
                      </IonButton>
                    </div>
                  </div>
                );
              })}
            </>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(EditRole);
