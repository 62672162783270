import { IonContent, IonFooter, IonHeader, IonMenu, IonTitle, IonToolbar } from "@ionic/react";
import { useRecoilValue, useRecoilValueLoadable, useSetRecoilState } from "recoil";
import {
  appInfoAtom,
  deviceInfoAtom,
  evidenceAtom,
  parapassDataAtom,
  plusDataAtom,
  progressCheckDataAtom,
  userAtom,
} from "../../state/State";
import { useRef, useState } from "react";
import { AllProgressCheckStatuses, IEvidence, IParapassUsageData, IPlusUsageData, IUser } from "../../Interfaces";
import MenuButton from "../../components/menu/MenuButton";
import {
  Button_Contact_Support,
  Button_Menu_Account,
  Button_Menu_Info,
  Button_Menu_Settings,
  Button_Menu_Sync,
  Button_Take_Survey,
} from "../../assets/images";
import { useHistory } from "react-router";
import { FEEDBACK_FORM_URL } from "../../Constants";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import DataController from "../../controllers/DataController";
import version from "../../environment/version";
import { Capacitor } from "@capacitor/core";
import { FirebaseService } from "../../controllers/FirebaseService";
import { DatabaseService } from "../../controllers/DatabaseService";
import "./Menu.css";
import * as ProgramUtils from "../../utils/programUtils";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import programData from "../../assets/json/programData.json";

const Menu: React.FC = () => {
  const history = useHistory();

  const menuRef = useRef<HTMLIonMenuElement>(null);

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData[] | null>(parapassDataAtom);
  const setAllProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const [syncingData, setSyncingData] = useState<boolean>(false);

  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const syncAccountData = (): void => {
    try {
      setSyncingData(true);

      if (user.state === "hasValue" && user.contents) {
        getUserEvidence();
        getJRCALCPlusData();
        getParapassData();
      }

      setTimeout(() => {
        setSyncingData(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setSyncingData(false);
    }
  };

  const getUserEvidence = async (): Promise<void> => {
    try {
      let allEvidence = [];
      allEvidence = await DatabaseService.getEvidence(user.contents);
      await DataController.saveEvidence(allEvidence);
      setEvidence(allEvidence);
    } catch (error) {
      console.log(error);
    }
  };

  const getJRCALCPlusData = async (): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getJRCALCPLusTimelineData(user.contents);
      await DataController.saveJRCALCPlusTimelineData(data);
      setPlusData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getParapassData = async (): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getParapassUsageData(user.contents);
      await DataController.saveParapassUsageData(data);
      setParapassData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProgressCheckData = async (): Promise<void> => {
    try {
      const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(
        user.contents?.userData.subscriptions,
        programData
      );
      const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

      if (progressCheckProgramIDs?.length > 0) {
        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(
            user.contents,
            progressCheckProgramIDs[i]
          );
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        setAllProgressCheckData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logButtonPressed = async (event: string, params: object): Promise<void> => {
    try {
      await FirebaseService.logEvent(event, params);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToPage = (page: string): void => {
    menuRef.current?.close();
    history.push(page);
  };

  const contactSupport = (): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - General support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  };

  const openExternalLink = (url: string): void => {
    menuRef.current?.close();
    window.open(url, "_blank");
  };

  return (
    <IonMenu
      ref={menuRef}
      side="start"
      type="push"
      // swipeGesture={Capacitor.getPlatform() !== 'web'}
      swipeGesture={false}
      maxEdgeStart={150}
      hidden={false}
      className="mainMenu"
      contentId="contentMain"
    >
      <IonHeader
        style={{
          backgroundColor: "#005F70",
          height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
        }}
      >
        <IonToolbar mode="ios" className="navBarMenu">
          <IonTitle>{""}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="menuContent">
        <MenuButton
          icon={Button_Menu_Account}
          title={user.contents?.email || "Unknown"}
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", { button: "Account" });
            navigateToPage("/profile/account");
          }}
        />
        <MenuButton
          icon={Button_Menu_Sync}
          title="Sync account data"
          syncingData={syncingData}
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Sync account data",
            });
            syncAccountData();
          }}
        />
        <MenuButton
          icon={Button_Menu_Settings}
          title="Accessibility settings"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Accessibility settings",
            });
            navigateToPage("/profile/accessibility");
          }}
        />
        <MenuButton
          icon={Button_Contact_Support}
          title="Contact support"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "Contact support",
            });
            contactSupport();
          }}
        />
        {/* <MenuButton
          icon={Button_Menu_Help}
          title="Help centre"
        /> */}
        <MenuButton
          icon={Button_Menu_Info}
          title="App information"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", {
              button: "App information",
            });
            navigateToPage("/profile/app-info");
          }}
        />
        <MenuButton
          icon={Button_Take_Survey}
          title="Take our survey"
          buttonClicked={() => {
            logButtonPressed("menu_button_pressed", { button: "Feedback" });
            openExternalLink(FEEDBACK_FORM_URL);
          }}
        />
      </IonContent>
      <div className="menuShadow" />
      <IonFooter className="menuFooter">
        <div className="menuFooterText">
          {Capacitor.getPlatform() === "web" ? (
            <>{`Version ${version}`}</>
          ) : (
            <>{`Version ${appInfo?.version} (${appInfo?.build})`}</>
          )}
        </div>
      </IonFooter>
    </IonMenu>
  );
};

export default Menu;
