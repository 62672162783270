import { useRecoilStateLoadable, useRecoilValueLoadable } from "recoil";
import { IEvidence, IMandatoryStandard, IUser, ProgramData } from "../../Interfaces";
import programsData from "../../assets/json/programData.json";
import { evidenceAtom, userAtom } from "../../state/State";
import * as ProgramUtils from "../../utils/programUtils";
import { useMemo } from "react";
import { subYears } from "date-fns";

const EprrOverview: React.FC<{}> = ({}) => {
  const [user, _] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);

  const allPrograms = programsData;
  const eprrProgram = allPrograms.find((item: any): item is ProgramData => item.ID === "LR0HYZYWY6JQK");

  let programEvidence: IEvidence[] = [];
  let draftEvidenceCount = 0;

  let mandatoryStandards: IMandatoryStandard[] = [];
  let mandatoryStandardsCompletionCount = 0;

  let optionalStandards: IMandatoryStandard[] = [];
  let optionalStandardsCompletionCount = 0;

  if (user.state === "hasValue" && evidence.state === "hasValue" && evidence.contents?.length && eprrProgram) {
    programEvidence = evidence.contents.filter((evidence: IEvidence) => {
      const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
      const programName = evidenceJSON?.programInfo?.program || "";

      const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

      return (
        programName === eprrProgram.Name &&
        onHoldReset !== 1 &&
        evidence.draft !== true &&
        new Date(evidence.date) >= subYears(new Date(), 2)
      );
    });
    draftEvidenceCount = evidence.contents.filter((evidence: IEvidence) => evidence.draft).length;

    const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);
    mandatoryStandards = ProgramUtils.getMandatoryStandardsFromProgram(eprrProgram, roleID);
    mandatoryStandardsCompletionCount = ProgramUtils.getCompliantCountForStandards(
      mandatoryStandards,
      programsData,
      user.contents,
      programEvidence
    );

    optionalStandards = ProgramUtils.getOptionalStandardsFromUser(user.contents, eprrProgram);
    optionalStandardsCompletionCount = ProgramUtils.getCompliantCountForStandards(
      optionalStandards,
      programsData,
      user.contents,
      programEvidence
    );
  }

  return (
    <div>
      <div className="mt-4">
        <div className="text-grey-90 text-acc-13px font-normal">Mandatory Standards</div>
        <div className="mt-2">
          <span
            className={`font-bold text-acc-34px text-grey-60 ${mandatoryStandardsCompletionCount > 0 && "text-progress-compliant-text"}`}
          >
            {mandatoryStandardsCompletionCount}
          </span>
          <span className="text-grey-60 font-bold text-acc-15px">{` / ${mandatoryStandards.length}`}</span>
        </div>
        <div className="flex gap-1 mt-2">
          {mandatoryStandards.map((standard, index) => (
            <div
              key={standard.id}
              className={`flex-1 h-[4px] bg-program-card-eprr-progress-marker rounded ${index < mandatoryStandardsCompletionCount && "bg-progress-compliant-text"}`}
            />
          ))}
        </div>
      </div>
      <div className="mt-4">
        <div className="text-grey-90 text-acc-13px font-normal">Optional Standards</div>
        <div className="mt-2">
          <span
            className={`font-bold text-acc-34px text-grey-60 ${optionalStandardsCompletionCount > 0 && "text-progress-compliant-text"}`}
          >
            {optionalStandardsCompletionCount}
          </span>
          <span className="text-grey-60 font-bold text-acc-15px">{` / ${optionalStandards.length}`}</span>
        </div>
        <div className="flex gap-1 mt-2">
          {optionalStandards.map((standard, index) => (
            <div
              key={standard.id}
              className={`flex-1 h-[4px] bg-program-card-eprr-progress-marker rounded ${index < optionalStandardsCompletionCount && "bg-progress-compliant-text"}`}
            />
          ))}
        </div>
      </div>
      <div className="flex mt-4">
        <div className="flex-1 flex flex-col justify-between">
          <div className="text-grey-90 text-acc-13px font-normal mb-2">Evidence added</div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">
            {programEvidence ? programEvidence.length : 0}
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between items-start">
          <div className="mb-2 rounded-xl bg-draft-badge text-draft-badge-text px-2 py-1 text-acc-12px font-semibold">
            Draft
          </div>
          <div className="text-program-card-eprr-text text-acc-28px font-bold">{draftEvidenceCount}</div>
        </div>
      </div>
    </div>
  );
};

export default EprrOverview;
