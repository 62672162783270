import React, { useEffect, useRef, useState } from "react";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { Comment, CommentsDisclaimer, ContentContainer, HeaderTitle } from "parafolio-components";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import "../../theme/tailwind.css";
import { EventRegister } from "react-native-event-listeners";
import { useRecoilValueLoadable } from "recoil";
import { progressCheckDataAtom, userAtom } from "../../state/State";
import {
  AllProgressCheckStatuses,
  IComment,
  IUser,
  ProgramData,
  ProgressCheck,
  ProgressCheckStatus,
} from "../../Interfaces";
import programsData from "../../assets/json/programData.json";
import { useLocation } from "react-router";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import CommentWrapper from "../../components/programs/CommentWrapper";
import _ from "lodash";
import { Icon_Refresh } from "../../assets/images";
import { CommentHelpers } from "../../utils/commentHelpers";
import * as ProgramUtils from "../../utils/programUtils";
import CommentInput from "../../components/programs/CommentInput";

const Comments: React.FC = (props) => {
  const location = useLocation();
  const bottom = useRef<HTMLDivElement>(null);

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | null>(null);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);
  const [comments, setComments] = useState<IComment[]>([]);
  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  }, []);

  useEffect(() => {
    const getProgramData = (): void => {
      if (location.pathname) {
        let pathname: string = location.pathname;
        pathname = pathname.substring(0, pathname.lastIndexOf("/"));
        const progressCheckID = pathname.substring(pathname.lastIndexOf("/") + 1);
        const pathname2 = pathname.replace(`/progress-check/${progressCheckID}`, "");
        const programID = pathname2.substring(pathname2.lastIndexOf("/") + 1);

        const allPrograms = programsData;
        const _program = allPrograms.find((item: any): item is ProgramData => item.ID === programID);

        const allProgressChecks: ProgressCheck[] | any[] = _program?.ProgressChecks || [];
        const _progressCheck: ProgressCheck | undefined = allProgressChecks.find(
          (item: any): item is ProgressCheck => item.ID === progressCheckID
        );

        if (_program) {
          setProgram(_program);
        }

        if (_progressCheck) {
          setProgressCheck(_progressCheck);
        }
      }
    };

    getProgramData();
  }, [location.pathname]);

  useEffect(() => {
    setTimeout(() => {
      bottom.current?.scrollIntoView({ behavior: "auto" });
    }, 100);
  }, []);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);

      if (dataForProgressCheck) {
        setProgressCheckData(dataForProgressCheck);
        setComments(_.cloneDeep(dataForProgressCheck.comments).reverse());
        bottom.current?.scrollIntoView({ behavior: "auto" });
      }
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const updateCommentReadTime = async (shouldUpdate?: boolean): Promise<void> => {
      let updated = await ProgressCheckService.markCommentsAsRead(user.contents, program?.ID, progressCheck!.ID);

      if (shouldUpdate && updated) {
        EventRegister.emit("comments/read-time-updated", user.contents);
      }
    };

    if (user.state === "hasValue" && progressCheck?.ID && comments.length > 0) {
      updateCommentReadTime();
    }

    return () => {
      if (user.state === "hasValue" && progressCheck?.ID && comments.length > 0) {
        updateCommentReadTime(true);
      }
    };
  }, [comments.length, program, progressCheck, user]);

  const sendComment = async (comment: string): Promise<void> => {
    try {
      const added = await ProgressCheckService.addComment(user.contents, program.ID, progressCheck?.ID || "", comment);
      added && EventRegister.emit("comments/comment-sent", user.contents);
    } catch (error) {
      console.log(error);
    }
  };

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{"Comments"}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="headerButton" mode="ios" onClick={() => refreshUserProgramData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="whiteBackground">
        <ContentContainer style={{ paddingTop: 0, paddingBottom: 0 }}>
          <CommentsDisclaimer isPreceptor={false} />
          {comments.map((item) => (
            <CommentWrapper key={item.date}>
              <Comment
                comment={CommentHelpers.mapServerCommentToComponent(item)}
                showNew={CommentHelpers.showNewCommentLabel(user.contents, item, progressCheckData?.commentReadTimes)}
              />
            </CommentWrapper>
          ))}
          <div ref={bottom} />
        </ContentContainer>
      </IonContent>
      <CommentInput
        commentsDisabled={ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions)}
        sendComment={(comment) => sendComment(comment)}
      />
    </IonPage>
  );
};

export default Comments;
