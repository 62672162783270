import { IProgramModuleEvidencePart } from "../../types/Components";
import _ from "lodash";
import EvidenceContentCard from "../common/EvidenceContentCard";
import { EvidencePart, IEvidence, IUser, LinkedPartType } from "../../Interfaces";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import { CreateEvidencePart } from "../programs/evidenceParts/ComponentWrapper";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom, userAtom } from "../../state/State";
import Collapsible from "react-collapsible";
import { useState } from "react";

const ProgramModuleEvidencePart: React.FC<IProgramModuleEvidencePart> = (props) => {
  const evidence = useRecoilValueLoadable<IEvidence[]>(evidenceAtom);
  const user = useRecoilValueLoadable<IUser>(userAtom);

  const [collapsibleOpen, setCollapsibleOpen] = useState<boolean>(false);

  const handleCollapsible = (value: boolean): void => setCollapsibleOpen(value);

  const toggleCollapsible = (): void => setCollapsibleOpen(!collapsibleOpen);

  if (!EvidenceUtils.checkIfEvidencePartIsRequired(props.evidencePart, props.evidenceJSON)) {
    return null;
  }

  if (props.evidencePart["Linked Part type"] === LinkedPartType.Child && !props.isChildPart) {
    return null;
  }

  const childParts = EvidenceUtils.getChildParts(props.evidencePart);

  if (EvidenceUtils.isBanner(props.evidencePart)) {
    return (
      <div>
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
        })}
      </div>
    );
  } else if (EvidenceUtils.isNOSSelect(props.evidencePart)) {
    return (
      <div>
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
          onValueChanged: props.onValueChanged,
          onInputBlurred: props.onInputBlurred,
          setExistingTagModalVisible: props.setExistingTagModalVisible,
          setEmptyTagModalVisible: props.setEmptyTagModalVisible,
          focusRichText: props.focusRichText,
          blurRichText: props.blurRichText,
          setCollapsibleOpen: handleCollapsible,
          toggleCollapsible: toggleCollapsible,
          collapsibleOpen: collapsibleOpen,
          isLocked: ProgramUtils.isFieldLocked(props.progressCheckCompetence?.ID, props.evidencePart.ID),
          initialValue: ProgramUtils.getEvidencePartInitialValue(
            props.evidencePart,
            props.evidenceJSON,
            evidence.contents,
            props.progressCheckCompetence
          ),
          id: `${props.competence?.Name}-${props.evidencePart.Name}-${props.index}`,
          program: props.program,
          user: user.contents,
          mandatoryStandards: props.mandatoryStandards,
          optionalStandards: props.optionalStandards,
        })}
      </div>
    );
  } else {
    return (
      <EvidenceContentCard
        mandatory={props.evidencePart.options === "Mandatory" || props.evidencePart["option type"] === "Mandatory"}
        title={props.evidencePart["Display Title"] || props.evidencePart.Name}
      >
        {CreateEvidencePart(props.evidencePart["Field type"], {
          definition: props.evidencePart,
          onValueChanged: props.onValueChanged,
          onInputBlurred: props.onInputBlurred,
          setExistingTagModalVisible: props.setExistingTagModalVisible,
          setEmptyTagModalVisible: props.setEmptyTagModalVisible,
          focusRichText: props.focusRichText,
          blurRichText: props.blurRichText,
          setCollapsibleOpen: handleCollapsible,
          toggleCollapsible: toggleCollapsible,
          collapsibleOpen: collapsibleOpen,
          isLocked: ProgramUtils.isFieldLocked(props.progressCheckCompetence?.ID, props.evidencePart.ID),
          initialValue: ProgramUtils.getEvidencePartInitialValue(
            props.evidencePart,
            props.evidenceJSON,
            evidence.contents,
            props.progressCheckCompetence
          ),
          id: `${props.competence?.Name}-${props.evidencePart.Name}-${props.index}`,
          program: props.program,
          user: user.contents,
        })}
        {childParts && (
          <Collapsible
            trigger={<div />}
            triggerDisabled
            transitionTime={200}
            transitionCloseTime={200}
            open={collapsibleOpen}
          >
            <div className="mt-[12px]">
              {childParts.map((evidencePart: EvidencePart, index) => (
                <ProgramModuleEvidencePart
                  evidencePart={evidencePart}
                  blurRichText={() => props.blurRichText?.()}
                  focusRichText={() => props.focusRichText?.()}
                  evidenceJSON={props.evidenceJSON}
                  index={index}
                  onInputBlurred={() => props.onInputBlurred?.()}
                  onValueChanged={(value: any, isInput?: boolean) =>
                    props.onChildValueChanged && props.onChildValueChanged(evidencePart, value, isInput)
                  }
                  key={`${props.competence?.Name}-${evidencePart.Name}-${index}`}
                  program={props.program}
                  competence={props.competence}
                  progressCheckCompetence={props.progressCheckCompetence}
                  setExistingTagModalVisible={() => props.setExistingTagModalVisible?.()}
                  setEmptyTagModalVisible={() => props.setEmptyTagModalVisible?.()}
                  isChildPart
                />
              ))}
            </div>
          </Collapsible>
        )}
      </EvidenceContentCard>
    );
  }
};

export default ProgramModuleEvidencePart;
