import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useLocation, withRouter } from "react-router";
import ContentContainer from "../../components/common/ContentContainer";
import DataController from "../../controllers/DataController";
import { EventRegister } from "react-native-event-listeners";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  EvidenceAttachment,
  EvidenceCustomTag,
  EvidencePart,
  EvidenceStandardTag,
  FieldType,
  IAccessibilitySettings,
  IEvidence,
  IUser,
  ProgramData,
  ProgressCheck,
  ProgressCheckStatus,
} from "../../Interfaces";
import {
  accessibilityAtom,
  appInfoAtom,
  deviceInfoAtom,
  evidenceAtom,
  progressCheckDataAtom,
  userAtom,
} from "../../state/State";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import DOMPurify from "dompurify";
import { format } from "date-fns";
import _ from "lodash";
import { FirebaseService } from "../../controllers/FirebaseService";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import { TextToSpeech } from "@capacitor-community/text-to-speech";
import { Capacitor } from "@capacitor/core";
import programData from "../../assets/json/programData.json";
import { CreateEvidencePart } from "../../components/programs/evidenceParts/ComponentWrapper";
import * as ProgramUtils from "../../utils/programUtils";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import { useCallback, useEffect, useState } from "react";
import { FileUtils } from "../../utils/fileUtils";
import { AccessibilityUtils } from "../../utils/accessibilityUtils";
import "../../theme/tailwind.css";

const ReadEvidence: React.FC = (props: any) => {
  const location = useLocation();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);
  const accessibilitySettings = useRecoilValueLoadable<IAccessibilitySettings | null>(accessibilityAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | null>(null);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[] | any[]>([]);
  const [progressCheckCompetence, setProgressCheckCompetence] = useState<CompetenceActivityClass | null>(null);
  const [progressCheckCompetences, setProgressCheckCompetences] = useState<CompetenceActivityClass[] | any[]>([]);

  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);

  const [evidence, setEvidence] = useState<IEvidence | null>(null);
  const [evidenceJSON, setEvidenceJSON] = useState<any>(null);
  const [competence, setCompetence] = useState<CompetenceActivityClass | any | null>(null);
  const [evidenceParts, setEvidenceParts] = useState<EvidencePart[] | any[]>([]);
  const [id, setId] = useState<string>("");
  const [viewingAttachment, setViewingAttachment] = useState<boolean>(false);
  const [viewingAttachmentFilename, setViewingAttachmentFilename] = useState<string>("");
  const [playing, setPlaying] = useState<boolean>(false);
  const [canEdit, setCanEdit] = useState<boolean>(false);

  const sendSupportEmail = useCallback((): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - Feedback";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  }, [appInfo, deviceInfo, user]);

  useEffect(() => {
    return () => {
      TextToSpeech.stop();
    };
  }, []);

  useEffect(() => {
    const handleClick = async (event: Event): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (element.href && element.href?.includes("contactSupport")) {
        event.preventDefault();
        await FirebaseService.logEvent("support_link_pressed", {
          link: "contact_support",
          type: "app_support",
        });

        sendSupportEmail();
      }
    };

    const handleAuxClick = async (event: MouseEvent): Promise<void> => {
      const element = event.target as HTMLAnchorElement;

      if (event.button === 1) {
        if (element.href?.includes("mailto:apps@class.co.uk")) {
          event.preventDefault();
          await FirebaseService.logEvent("support_link_pressed", {
            link: "contact_support",
            type: "app_info_feedback",
          });

          sendSupportEmail();
        }
      }
    };

    document.addEventListener("click", handleClick);
    document.addEventListener("auxclick", handleAuxClick);

    return () => {
      document.removeEventListener("click", handleClick);
      document.removeEventListener("auxclick", handleAuxClick);
    };
  }, [sendSupportEmail]);

  useEffect(() => {
    const checkEvidenceIsLocked = (): void => {
      const canEdit = ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions);

      setCanEdit(canEdit);
    };

    checkEvidenceIsLocked();
  }, [progressCheckData]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);
      dataForProgressCheck && setProgressCheckData(dataForProgressCheck);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const getEvidence = (): void => {
      if (location.pathname) {
        const pathname: string = location.pathname;
        const id = pathname.substring(pathname.lastIndexOf("/") + 1);

        const _evidence: IEvidence = allEvidence.contents.find((item: IEvidence) => item.id === id);

        if (_evidence) {
          setEvidence(_evidence);
          setId(_evidence.id);

          if (_evidence.evidenceJSON) {
            const _evidenceJSON = JSON.parse(_evidence.evidenceJSON);

            const competenceName = _evidenceJSON.programInfo?.competence;
            const programName = _evidenceJSON.programInfo?.program;

            const _program = programData.find((item: any): item is ProgramData => item && item.Name === programName);
            const _competences: any = _program?.CompetenceActivity || [];

            const _competence = _competences?.find(
              (item: any): item is CompetenceActivityClass => item.Name === competenceName
            );

            if (_program) {
              if (ProgramUtils.isPreceptorship(_program.ID)) {
                setProgram(_program);

                const progressCheckID = _evidenceJSON.programInfo?.progressCheckID;
                const progressCheckCompetenceID = _evidenceJSON.programInfo?.progressCheckCompetenceID;

                const _progressChecks: any = _program.ProgressChecks ?? [];
                // console.log("ProgressCheckCompetenceID: ", progressCheckCompetenceID);
                // console.log('ProgressChecks: ', _progressChecks);

                if (_progressChecks) {
                  setProgressChecks(_progressChecks);

                  const _progressCheck = _progressChecks.find(
                    (item: any): item is ProgressCheck => item.ID === progressCheckID
                  );
                  // console.log('ProgressCheck: ', _progressCheck);

                  if (_progressCheck) {
                    setProgressCheck(_progressCheck);

                    const _progressCheckCompetences = _progressCheck["Competence/Activity"] || [];
                    // console.log('ProgressChecksCompetences: ', _progressCheckCompetences);

                    if (_progressCheckCompetences) {
                      setProgressCheckCompetences(_progressCheckCompetences);

                      const _progressCheckCompetence = _progressCheckCompetences.find(
                        (item: any): item is CompetenceActivityClass => item.ID === progressCheckCompetenceID
                      );

                      if (_progressCheckCompetence) {
                        const evidenceDefinitions = _progressCheckCompetence?.["Evidence Definitions"][0];
                        // console.log("ProgressCheckCompetence: ", _progressCheckCompetence);
                        // console.log("EvidenceDefinitions: ", evidenceDefinitions);

                        const _evidenceParts = EvidenceUtils.getEvidenceParts(evidenceDefinitions);

                        setProgressCheckCompetence(_progressCheckCompetence);
                        setEvidenceParts(_evidenceParts);
                      }
                    }
                  }
                }
              } else {
                const _competences = _program.CompetenceActivity || [];
                const _evidenceDefinitions = _program["Evidence Definitions"] || [];

                setProgram(_program);

                if (_competences && _competences.length > 0) {
                  setCompetences(_competences);

                  if (_competence) {
                    const evidenceDefinitions = _competence["Evidence Definitions"][0];

                    const _evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];

                    setEvidenceParts(_evidenceParts);
                    setCompetence(_competence);
                  }
                }

                if (_evidenceDefinitions && _evidenceDefinitions.length > 0) {
                  const _evidenceParts = _evidenceDefinitions[0]?.["Evidence parts"] || [];
                  setEvidenceParts(_evidenceParts);
                }
              }
            }

            setEvidenceJSON(_evidenceJSON);
          }
        }
      }
    };

    if (allEvidence.state === "hasValue" && allEvidence.contents) {
      getEvidence();
    }
  }, [allEvidence, location, progressCheckCompetences, setEvidence]);

  useEffect(() => {
    EventRegister.emit("tab-bar/visibility-changed", false);

    return () => EventRegister.emit("tab-bar/visibility-changed", true);
  });

  const editEvidence = (): void => {
    let _evidence = _.pickBy(_.cloneDeep(evidence), _.identity);

    if (_evidence.evidenceJSON) {
      const evidenceDefinitions = program?.["Evidence Definitions"] || [];
      const mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program);
      const optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program);

      const data = {
        program: program,
        ...(competences.length > 0 && { competences }),
        ...(evidenceDefinitions.length > 0 && {
          evidenceDefinitions: evidenceDefinitions[0],
        }),
        competence: competence,
        evidence: _evidence,
        progressCompetences: progressCheckCompetences,
        ...(progressCheckCompetence && {
          progressCompetence: progressCheckCompetence,
        }),
        progressCheck: progressCheck,
        ...(progressChecks.length > 0 && { progressChecks }),
        ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
        ...(optionalStandards.length > 0 && { optionalStandards }),
        evidenceJSON: JSON.parse(_evidence.evidenceJSON),
        skill: null,
        id: _evidence.id,
      };

      EventRegister.emit("evidence/add-program-evidence", data);
    } else {
      EventRegister.emit("evidence/evidence-card-clicked", _evidence);
    }
  };

  const viewAttachment = async (attachment: EvidenceAttachment): Promise<void> => {
    try {
      if (attachment.url && user.contents) {
        setViewingAttachment(true);
        setViewingAttachmentFilename(attachment.name);

        await FileUtils.viewAttachment(user.contents, id, attachment);

        setViewingAttachment(false);
        setViewingAttachmentFilename("");
      }
    } catch (error) {
      console.log(error);
      setViewingAttachment(false);
      setViewingAttachmentFilename("");
      window.alert("An error occurred when opening attachment");
    }
  };

  const sanitizeData = (data: string) => {
    console.log(data);
    console.log(DOMPurify.sanitize(data));
    return { __html: DOMPurify.sanitize(data) };
  };

  const playPauseAudio = useCallback(async (): Promise<void> => {
    try {
      if (playing) {
        setPlaying(false);
        TextToSpeech.stop();
      } else {
        let content = document.getElementById("readContainer");
        let supportedLanguages = await TextToSpeech.getSupportedLanguages();
        let text: string = content?.innerText || "";

        if (supportedLanguages.languages.length > 0) {
          setPlaying(true);
          const textToSpeechProperties = AccessibilityUtils.getTextToSpeechProperties(text, supportedLanguages);

          await TextToSpeech.speak(textToSpeechProperties);
          setPlaying(false);
        }
      }
    } catch (error) {
      console.log(error);
      TextToSpeech.stop();
    }
  }, [playing]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref="/evidence"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <IonButtons slot="end">
            {accessibilitySettings.contents?.textToSpeech && (
              <IonButton className="headerButton" mode="ios" onClick={() => playPauseAudio()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            )}
            {canEdit && (
              <IonButton mode="ios" className="cancelButton" onClick={() => editEvidence()}>
                {"Edit"}
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground whiteBackground">
        <ContentContainer tabBarDisabled id="readContainer">
          {evidence ? (
            <>
              {evidenceParts.length > 0 ? (
                <>
                  {evidenceJSON.Title && EvidenceUtils.titleHasNoData(evidenceParts) && (
                    <div>
                      <div className="readEvidenceTitle">{evidenceJSON.Title}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidenceParts.map((item: EvidencePart, index: number) => {
                    let dataForPart;
                    let mandatoryStandards;
                    let optionalStandards;

                    if (item["Field type"] === FieldType.NOSSelect) {
                      dataForPart = {
                        Comps: evidenceJSON.Comps,
                        LOs: evidenceJSON.LOs,
                      };

                      mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program);
                      optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program);
                    } else {
                      dataForPart = evidenceJSON[item.Name];
                    }

                    if (dataForPart) {
                      return (
                        <div className="readEvidenceBlock" key={`${item.Name}-${index}`}>
                          {CreateEvidencePart(item["Field type"], {
                            definition: item,
                            data: dataForPart,
                            isDisplay: true,
                            evidenceID: evidence.id,
                            user: user.contents,
                            mandatoryStandards,
                            optionalStandards,
                          })}
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                  <div className="readEvidenceBlock">
                    <div className="readEvidenceHeader">{"Included in audit documents"}</div>
                    <div className="readEvidenceText">{evidence.addToHCPCAudit ? "Yes" : "No"}</div>
                  </div>
                </>
              ) : (
                <>
                  {evidence.title && (
                    <div>
                      <div className="readEvidenceTitle">{evidence.title}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.date && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Date of reflection"}</div>
                      <div className="readEvidenceText">{format(new Date(evidence.date), "d MMM yyyy")}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.activityDate && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Date of activity"}</div>
                      <div className="readEvidenceText">{format(new Date(evidence.activityDate), "d MMM yyyy")}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.activity && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"CPD Activity"}</div>
                      <div className="readEvidenceText">{evidence.activity.type}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.numberOfHours && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Number of hours"}</div>
                      <div className="readEvidenceText">
                        {DataController.convertToHoursMinutes(evidence.numberOfHours)}
                      </div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.standardTags && evidence.standardTags.length > 0 && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"National Occupational Standards"}</div>
                      <div className="readEvidenceText">
                        {evidence.standardTags
                          .map((tag: EvidenceStandardTag) => tag.tag)
                          .sort()
                          .join(", ")}
                      </div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.customTags && evidence.customTags.length > 0 && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Custom tags"}</div>
                      <div className="readEvidenceText">
                        {evidence.customTags
                          .map((tag: EvidenceCustomTag) => tag.tag)
                          .sort()
                          .join(", ")}
                      </div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.attachments && evidence.attachments.length > 0 && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Attachments"}</div>
                      <div className="readEvidenceText">
                        {evidence.attachments.map((attachment: EvidenceAttachment) => {
                          const disabled = viewingAttachment && viewingAttachmentFilename !== attachment.name;
                          const viewing = viewingAttachment && viewingAttachmentFilename === attachment.name;

                          return (
                            <div
                              key={attachment.name}
                              id={attachment.name}
                              className={`readEvidenceAttachmentContainer ${viewing ? "readEvidenceAttachmentContainerWait" : ""} ${disabled ? "readEvidenceAttachmentContainerDisabled" : ""}`}
                              onClick={() => !(disabled || viewing) && viewAttachment(attachment)}
                            >
                              <div className="readEvidenceAttachment">{attachment.name}</div>
                              {viewing && (
                                <div className="readEvidenceSpinnerContainer">
                                  <IonSpinner className="readEvidenceAttachmentIconSpinner" />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.activityDescription && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Activity description"}</div>
                      <div className="readEvidenceText">{evidence.activityDescription}</div>
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.reflections && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Reflection"}</div>
                      <div className="readEvidenceText" dangerouslySetInnerHTML={sanitizeData(evidence.reflections)} />
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.benefits && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Benefits"}</div>
                      <div className="readEvidenceText" dangerouslySetInnerHTML={sanitizeData(evidence.benefits)} />
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  {evidence.objectives && (
                    <div className="readEvidenceBlock">
                      <div className="readEvidenceHeader">{"Learning / Exercise Objectives"}</div>
                      <div className="readEvidenceText" dangerouslySetInnerHTML={sanitizeData(evidence.objectives)} />
                      <div className="h-[1px] w-full bg-grey-30 mt-4" />
                    </div>
                  )}
                  <div className="readEvidenceBlock">
                    <div className="readEvidenceHeader">{"Included in audit documents"}</div>
                    <div className="readEvidenceText">{evidence.addToHCPCAudit ? "Yes" : "No"}</div>
                  </div>
                </>
              )}
            </>
          ) : (
            <>
              <div className="readEvidenceTitle">{"This piece of evidence doesn't exist"}</div>
              <div className="readEvidenceBlock">
                <div className="readEvidenceText">
                  {
                    "You may have deleted this piece of evidence recently, try going back and selecting another piece of evidence."
                  }
                  <br />
                  <br />
                  {"If you think this is a mistake, please "}
                  <a className="text-cta-blue underline" href="contactSupport">
                    {"contact support"}
                  </a>
                </div>
              </div>
            </>
          )}
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(ReadEvidence);
