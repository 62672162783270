import React from "react";
import { IProgramEvidenceCard } from "../../types/Components";
import { EvidenceCard } from "parafolio-components";
import { format, subYears } from "date-fns";
import { EvidenceCustomTag, EvidenceStandardTag, ProgressCheck } from "../../Interfaces";
import * as ProgramUtils from "../../utils/programUtils";

const ProgramEvidenceCard: React.FC<IProgramEvidenceCard> = (props) => {
  const evidenceJSON = props.programEvidence.evidenceJSON && JSON.parse(props.programEvidence.evidenceJSON);
  const title = props.programEvidence.title || evidenceJSON?.Title || "";

  const activityTypeKey = ProgramUtils.getEvidenceActivityTypeKey(props.programEvidence);

  const activityType = activityTypeKey && evidenceJSON?.[activityTypeKey];
  const taskingType = evidenceJSON?.["Tasking type or training"];

  const customTagsKey = ProgramUtils.getEvidenceCustomTagsKey(props.programEvidence);
  const customTags: EvidenceCustomTag[] = customTagsKey && evidenceJSON?.[customTagsKey];

  const progressCheck: ProgressCheck | null =
    evidenceJSON?.programInfo?.progressCheckID && ProgramUtils.getProgressCheckForEvidence(evidenceJSON, props.program);

  const hasSeparator =
    typeof activityType !== "undefined" ||
    (typeof progressCheck !== "undefined" && typeof props.program !== "undefined");

  const onHoldReset = evidenceJSON?.OnHoldReset === 1;

  const getLabel = () => {
    if (props.program && ProgramUtils.showStandardsLabelForEvidenceCard(props.program.ID)) {
      try {
        let standards: string[] = [];
        const parsedEvidence = JSON.parse(props.programEvidence.evidenceJSON);
        const comps: any[] = Array.from(parsedEvidence.Comps);
        comps.forEach((comp) => standards.push(comp.Name));
        return standards.join(", ");
      } catch (error) {
        return "";
      }
    } else {
      return "";
    }
  };

  const label = getLabel();

  const expired = new Date(props.programEvidence.date) < subYears(new Date(), 2);

  return (
    <div>
      <div className="flex justify-center">
        <EvidenceCard
          onClick={props.buttonPressed}
          style={{
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 12,
            paddingBottom: 12,
          }}
        >
          <div className="flex flex-row flex-wrap items-center">
            {expired && (
              <div className="flex items-center justify-center self-start h-[16px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                <div className="text-[12px] font-semibold text-[#9C4221]">{"Expired"}</div>
              </div>
            )}
            {props.programEvidence.draft && (
              <div className="flex items-center justify-center self-start h-[16px] w-[42px] px-[6px] rounded-[13px] mr-[8px] bg-orange-30 ">
                <div className="text-[12px] font-semibold text-[#9C4221]">{"Draft"}</div>
              </div>
            )}
            <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-grey-70">
              {format(new Date(props.programEvidence.date), "d MMM yyyy")}
            </div>
            {hasSeparator && <div className="evidenceCardDotSeparator" />}
            {activityType && <div className="evidenceCardProgramActivity">{activityType}</div>}
            {progressCheck && (
              <div className="text-acc-12px font-acc-600 leading-acc-lh text-program-evidence-header">
                {progressCheck.Name}
              </div>
            )}
            {onHoldReset && (
              <div className="flex flex-row">
                <div className="evidenceCardDotSeparator" />
                <div className="text-acc-12px font-acc-500 leading-[1.33] tracking-[-0.1px] text-cta-red whitespace-break-spaces">
                  {"Submission Reset"}
                </div>
              </div>
            )}
          </div>
          <div
            className={`acc-underline text-acc-20px font-semibold leading-acc-lh tracking-[-0.1px] text-grey-90 mt-[4px] evidence-m:text-acc-17px evidence-m:leading-default ${label.length === 0 && "mb-[8px]"}`}
          >
            {title}
          </div>
          <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible acc-underline">
            {taskingType && <div>{taskingType}</div>}
            {props.programEvidence.standardTags && props.programEvidence.standardTags?.length > 0 && (
              <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-[#2F855A] overflow-visible">
                {props.programEvidence.standardTags.map((tag: EvidenceStandardTag) => tag.tag).join(", ")}
              </span>
            )}
            {props.programEvidence.standardTags &&
              props.programEvidence.standardTags.length > 0 &&
              props.programEvidence.customTags &&
              props.programEvidence.customTags.length > 0 && (
                <span className="inline-block self-center w-[2px] h-[2px] rounded-[4px] mx-[5px] my-[3.5px] bg-grey-70" />
              )}
            {props.programEvidence.customTags && props.programEvidence.customTags.length > 0 && (
              <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible">
                {props.programEvidence.customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
              </span>
            )}
            {customTags && customTags.length > 0 && (
              <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-70 overflow-visible">
                {customTags.map((tag: EvidenceCustomTag) => tag.tag).join(", ")}
              </span>
            )}
            {label && label.length > 0 && (
              <span className="text-acc-12px font-acc-500 leading-default tracking-[-0.1px] text-grey-60 overflow-visible">
                {label}
              </span>
            )}
          </span>
          {props.programEvidence.addToHCPCAudit && <div className="evidenceHCPCPill">{"Audit"}</div>}
        </EvidenceCard>
      </div>
    </div>
  );
};

export default ProgramEvidenceCard;
