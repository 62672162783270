import { PropsWithChildren, useState } from "react";
import { IDetailsContentCollapsible } from "../../types/Components";
import * as ProgramUtils from "../../utils/programUtils";
import Collapsible from "react-collapsible";

const DetailsContentCollapsible = (props: PropsWithChildren<IDetailsContentCollapsible>) => {
  const [isOpen, setIsOpen] = useState(false);

  const showBetaLabel = ProgramUtils.showBetaLabelForDetailsCollapsible(props.programID);

  return (
    <Collapsible
      onOpening={() => setIsOpen(true)}
      onClosing={() => setIsOpen(false)}
      trigger={
        <>
          <div className="flex justify-between">
            <div className={`text-white bg-NWAS px-1 rounded-[2px] font-semibold text-13px`}>{"NWAS"}</div>
            {showBetaLabel && (
              <div className="text-white bg-black px-1 rounded-[2px] font-semibold text-13px">{"BETA"}</div>
            )}
          </div>
          <div className="flex justify-between items-center mt-2">
            <div className="text-acc-17px font-extrabold text-grey-80">{props.title}</div>
            <div className="text-[13px] font-semibold tracking-[-0.05px] text-cta-blue acc-underline">
              {`${isOpen ? "Hide" : "View"} details`}
            </div>
          </div>
        </>
      }
    >
      {props.children}
    </Collapsible>
  );
};

export default DetailsContentCollapsible;
