import { useRecoilValueLoadable } from "recoil";
import { AllProgressCheckStatuses, ProgressCheck, ProgressCheckStatus } from "../../Interfaces";
import { progressCheckDataAtom } from "../../state/State";
import { useEffect, useState } from "react";
import _ from "lodash";

// export const useProgressCheckData = (programID: string = "", progressCheck?: ProgressCheck) => {
//   const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

//   const programProgressCheckData: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
//     (item: any): item is AllProgressCheckStatuses => item.programID === programID
//   );

//   const progressCheckData = programProgressCheckData?.pCs.find((item) => item.pCId === progressCheck?.ID) ?? null;

//   console.log(programProgressCheckData, progressCheckData);

//   return { programProgressCheckData, progressCheckData };
// };

export const useProgressCheckData = (programID: string = "", progressCheck?: ProgressCheck) => {
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);
  const [programProgressCheckData, setProgramProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === programID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID) ?? null;

      if (!_.isEqual(programProgressCheckData, dataForProgram) && dataForProgram) {
        setProgramProgressCheckData(dataForProgram);
      }

      if (!_.isEqual(progressCheckData, dataForProgressCheck) && dataForProgressCheck) {
        setProgressCheckData(dataForProgressCheck);
      }
    };

    allProgressCheckData.state === "hasValue" && getProgressCheckData();
  }, [allProgressCheckData.contents, programID]);

  return { programProgressCheckData, progressCheckData };
};
