import {
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonList,
  IonMenuButton,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory, withRouter } from "react-router";
import { useRecoilStateLoadable, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import {
  Button_Accessibility,
  Button_Copy,
  Button_Copyright,
  Button_Help_Grey60,
  Button_Info,
  Button_Logout,
  Button_Menu,
  Button_Settings,
  Button_Survey_Grey60,
  Icon_Sync,
} from "../../assets/images";
import ContentContainer from "../../components/common/ContentContainer";
import DashboardCard from "../../components/home/DashboardCard";
import ProfileSettingsButton from "../../components/profile/ProfileSettingsButton";
import ProfileSettingsCard from "../../components/profile/ProfileSettingsCard";
import AuthenticationController from "../../controllers/AuthenticationController";
import AWSService from "../../controllers/AWSService";
import { FirebaseService } from "../../controllers/FirebaseService";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  IAccessibilitySettings,
  IAppFeatures,
  IEvidence,
  IEvidenceDraft,
  IParapassUsageData,
  IPlusUsageData,
  IUser,
  IUserCertificate,
} from "../../Interfaces";
import {
  evidenceAtom,
  guidelinesCardDismissedAtom,
  plusDataAtom,
  parapassDataAtom,
  userAtom,
  welcomeDismissedAtom,
  appFeaturesAtom,
  accessibilityAtom,
  programInfoDismissedAtom,
  draftsHintDismissedAtom,
  deviceInfoAtom,
  appInfoAtom,
  evidenceDraftsAtom,
  progressCheckDataAtom,
} from "../../state/State";
import roles from "../../assets/json/roles.json";
import roleCertificates from "../../assets/json/role-certificates.json";
import _ from "lodash";
import "./Profile.css";
import HCPCProfileCard from "../../components/profile/HCPCProfileCard";
import SummaryOfWorkModal from "../../components/profile/SummaryOfWorkModal";
import PersonalStatementModal from "../../components/profile/PersonalStatementModal";
import { Directory } from "@capacitor/filesystem";
import { Clipboard } from "@capacitor/clipboard";
import WriteBlob from "capacitor-blob-writer";
import { FileOpener } from "@capacitor-community/file-opener";
import mime from "mime";
import DataController from "../../controllers/DataController";
import { DatabaseService } from "../../controllers/DatabaseService";
import { FEEDBACK_FORM_URL, TERMS_AND_CONDITIONS_URL } from "../../Constants";
import HeaderTitle from "../../components/common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import * as ProgramUtils from "../../utils/programUtils";
import programData from "../../assets/json/programData.json";

const Profile: React.FC = () => {
  const history = useHistory();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const appFeatures = useRecoilValue<IAppFeatures[] | string[]>(appFeaturesAtom);

  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);

  const setEvidence = useSetRecoilState<IEvidence[] | null>(evidenceAtom);
  const setGuidelinesCardDismissed = useSetRecoilState<boolean>(guidelinesCardDismissedAtom);
  const setWelcomeDismissed = useSetRecoilState<boolean>(welcomeDismissedAtom);
  const setPlusData = useSetRecoilState<IPlusUsageData[] | null>(plusDataAtom);
  const setParapassData = useSetRecoilState<IParapassUsageData[] | null>(parapassDataAtom);
  const setAccessibilitySettings = useSetRecoilState<IAccessibilitySettings | null>(accessibilityAtom);
  const setInfoDismissed = useSetRecoilState<any[]>(programInfoDismissedAtom);
  const setDraftsHintDismissed = useSetRecoilState<boolean>(draftsHintDismissedAtom);
  const setDrafts = useSetRecoilState<IEvidenceDraft[] | null>(evidenceDraftsAtom);
  const setAllProgressCheckData = useSetRecoilState<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const resetUser = useResetRecoilState(userAtom);
  const resetAllProgressCheckData = useResetRecoilState(progressCheckDataAtom);

  const [summaryOfWorkModalVisible, setSummaryOfWorkModalVisible] = useState<boolean>(false);
  const [personalStatementModalVisible, setPersonalStatementModalVisible] = useState<boolean>(false);

  const [viewingCertificate, setViewingCertificate] = useState<boolean>(false);
  const [viewingCertificateFilename, setViewingCertificateFilename] = useState<string>("");

  const [hasRoleCertificates, setHasRoleCertificates] = useState<boolean>(false);
  const [certificatesForRole, setCertificatesForRole] = useState<IUserCertificate[]>([]);

  const [syncingData, setSyncingData] = useState<boolean>(false);

  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    const setPageName = async (): Promise<void> => {
      await FirebaseService.setScreenName("profile");
    };

    setPageName();
  }, []);

  useEffect(() => {
    const checkCertificates = (): void => {
      const userRole = user && user.contents.role;
      let certificates = _.cloneDeep(user.contents.certificates) || [];
      let mappedCertificates = certificates.map((item: IUserCertificate) => item.certificate?.certificateType);

      let _role = roles.find((item) => item.Name === userRole);

      if (_role) {
        let _certificates = roleCertificates.filter((item) => item.Roles.includes(_role?.id || ""));
        _certificates = _certificates.filter((item) => mappedCertificates.includes(item.Name));

        let _mappedCertificates = _certificates.map((item) => item.Name);
        certificates = certificates.filter(
          (item: IUserCertificate) =>
            item.certificate && _mappedCertificates.includes(item.certificate?.certificateType)
        );

        setHasRoleCertificates(_certificates.length > 0);
        setCertificatesForRole(certificates);
      }
    };

    if (user.state === "hasValue" && user.contents) {
      checkCertificates();
    }
  }, [user]);

  const openLink = (url: string): void => {
    try {
      window.open(url, "_blank");
    } catch (error) {
      console.log(error);
    }
  };

  const getUserEvidence = async (): Promise<void> => {
    try {
      let allEvidence = [];
      allEvidence = await DatabaseService.getEvidence(user.contents);
      await DataController.saveEvidence(allEvidence);
      setEvidence(allEvidence);
    } catch (error) {
      console.log(error);
    }
  };

  const getJRCALCPlusData = async (): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getJRCALCPLusTimelineData(user.contents);
      await DataController.saveJRCALCPlusTimelineData(data);
      setPlusData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getParapassData = async (): Promise<void> => {
    try {
      let data = [];
      data = await DatabaseService.getParapassUsageData(user.contents);
      await DataController.saveParapassUsageData(data);
      setParapassData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getProgressCheckData = async (): Promise<void> => {
    try {
      const programIDs = ProgramUtils.getProgramIDsFromSubscriptions(
        user.contents?.userData.subscriptions,
        programData
      );
      const progressCheckProgramIDs = ProgramUtils.filterProgressCheckProgramIDs(programIDs);

      if (progressCheckProgramIDs?.length > 0) {
        let data = [];

        for (let i = 0; i < progressCheckProgramIDs.length; i++) {
          let result = await ProgressCheckService.getAllProgressCheckStatuses(
            user.contents,
            progressCheckProgramIDs[i]
          );
          data.push({ ...result, programID: progressCheckProgramIDs[i] });
        }

        setAllProgressCheckData(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const logout = async (): Promise<void> => {
    await AuthenticationController.logout();
    setEvidence(null);
    setPlusData(null);
    setParapassData(null);
    setAccessibilitySettings(null);
    setWelcomeDismissed(false);
    setDraftsHintDismissed(false);
    setGuidelinesCardDismissed(false);
    setInfoDismissed([]);
    setDrafts(null);
    resetUser();
    resetAllProgressCheckData();

    history.replace("/onboarding/login");
  };

  const openCertificate = async (item: IUserCertificate): Promise<void> => {
    try {
      setViewingCertificate(true);
      setViewingCertificateFilename(item.certificate?.name || "");

      const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

      const file = await AWSService.openCertificateFromServer(user.contents, accessParams, item.certificate);

      if (await DataController.isWebAsync()) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          })
        );
        window.open(url, "_blank");
      } else {
        const blob = new Blob([new Uint8Array(file.Body.data)], {
          type: file.ContentType,
        });

        const filePath = await WriteBlob({
          path: `ParaFolio/Certificates/${item.certificate?.name}.${mime.getExtension(file.ContentType)}`,
          blob,
          directory: Directory.Library,
          recursive: true,
        });

        FileOpener.open({
          filePath,
        });
      }

      setViewingCertificate(false);
      setViewingCertificateFilename("");
    } catch (error) {
      console.log(error);
      window.alert("An error occurred when opening certificate");
      setViewingCertificate(false);
      setViewingCertificateFilename("");
    }
  };

  const logButtonPressed = async (event: string, params: object): Promise<void> => {
    try {
      await FirebaseService.logEvent(event, params);
    } catch (error) {
      console.log(error);
    }
  };

  const navigateToPage = (page: string): void => {
    history.push(page);
  };

  const copyHCPCButton = async (): Promise<void> => {
    setCopied(true);

    if (await DataController.isWebAsync()) {
      navigator.clipboard.writeText(user.contents?.HCPCNumber);
    } else {
      Clipboard.write({
        string: user.contents?.HCPCNumber,
      });
    }

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  };

  const contactSupport = (): void => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = "ParaFolio - General support";

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  };

  const toggleSummaryOfWorkModalVisible = (value: boolean): void => {
    setSummaryOfWorkModalVisible(value);
  };

  const togglePersonalStatementModalVisible = (value: boolean): void => {
    setPersonalStatementModalVisible(value);
  };

  const syncAccountData = async (): Promise<void> => {
    try {
      setSyncingData(true);

      if (user.state === "hasValue" && user.contents) {
        getUserEvidence();
        getJRCALCPlusData();
        getParapassData();
        getProgressCheckData();
      }

      setTimeout(() => {
        setSyncingData(false);
      }, 1500);
    } catch (error) {
      console.log(error);
      setSyncingData(false);
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonMenuButton>
              <IonImg src={Button_Menu} className="menuIcon" />
            </IonMenuButton>
          </IonButtons>
          <HeaderTitle>{"Profile"}</HeaderTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground">
        <SummaryOfWorkModal
          visible={summaryOfWorkModalVisible}
          closeModal={(value) => toggleSummaryOfWorkModalVisible(value)}
        />
        <PersonalStatementModal
          visible={personalStatementModalVisible}
          closeModal={(value) => togglePersonalStatementModalVisible(value)}
        />
        <ContentContainer id="profileContentContainer">
          <DashboardCard
            title={user ? `${user.state === "hasValue" ? user.contents?.name || user.contents?.email : ""}` : "User"}
            subtitle="Your details"
            isProfile
            sideButton
            sideButtonText="Edit"
            buttonClicked={() => navigateToPage("/profile/edit")}
          >
            <div style={{ marginTop: -22 }}>
              <div className="homeCardUserInfoVertical">
                <div className="homeCardUserDetailsHeader">{"Email"}</div>
                <div className="homeCardUserDetailsText">{user.contents?.email || "Unknown"}</div>
              </div>
              {appFeatures.includes(IAppFeatures.SWAST_MIC) && user.state === "hasValue" && user.contents?.role && (
                <div className="homeCardUserInfoVertical">
                  <div className="homeCardUserDetailsHeader">{"Your roles"}</div>
                  <div className="homeCardUserDetailsText">{user.contents?.role || "Unknown"}</div>
                </div>
              )}
              {appFeatures.includes(IAppFeatures.SWAST_MIC) &&
                user.state === "hasValue" &&
                user.contents?.certificates &&
                hasRoleCertificates && (
                  <div className="homeCardUserInfoVertical">
                    <div className="homeCardUserDetailsHeader">{"Certificates"}</div>
                    {certificatesForRole.map((item, index) => {
                      const disabled = viewingCertificate && viewingCertificateFilename !== item.certificate?.name;
                      const viewing = viewingCertificate && viewingCertificateFilename === item.certificate?.name;

                      return (
                        <button
                          disabled={disabled}
                          key={item.certificate?.name || index}
                          className={`homeCardUserInfoButtonSlim ${viewing ? "homeCardUserInfoButtonSlimWait" : ""} ${disabled ? "homeCardUserInfoButtonSlimDisabled" : ""}`}
                          onClick={() => !(disabled || viewing) && openCertificate(item)}
                        >
                          <div className="homeCardCertificateTextGeneral">{item.certificate?.name || "Unknown"}</div>
                          {viewing && <IonSpinner className="homeCardCertificateSpinner" />}
                        </button>
                      );
                    })}
                  </div>
                )}
              {user.state === "hasValue" && user.contents?.HCPCNumber && (
                <div className="homeCardUserInfoVertical">
                  <div className="homeCardUserDetailsHeader">{"Registration number"}</div>
                  <div className="homeCardHCPCRegistrationNumberContainer">
                    <div className="homeCardHCPCRegistrationNumber">{user.contents?.HCPCNumber || "Unknown"}</div>
                    <button className="homeCardUserCopyButton" disabled={copied} onClick={copyHCPCButton}>
                      {copied ? (
                        <div className="homeCardUserCopyButtonText">{"Copied"}</div>
                      ) : (
                        <>
                          <div className="homeCardUserCopyButtonText">{"Copy"}</div>
                          <img src={Button_Copy} className="hcpcCopyIcon" alt="" />
                        </>
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </DashboardCard>
          <HCPCProfileCard
            personalStatementPressed={() => togglePersonalStatementModalVisible(true)}
            summaryOfWorkPressed={() => toggleSummaryOfWorkModalVisible(true)}
          />
          <ProfileSettingsCard title="More">
            <IonList lines="none" className="profileSettingsList">
              <ProfileSettingsButton
                icon={Icon_Sync}
                title="Sync account data"
                syncingData={syncingData}
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Sync account data",
                  });
                  syncAccountData();
                }}
              />
              <ProfileSettingsButton
                icon={Button_Settings}
                title="Account settings"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Account",
                  });
                  navigateToPage("/profile/account");
                }}
              />
              <ProfileSettingsButton
                icon={Button_Accessibility}
                title="Accessibility settings"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Accessibility settings",
                  });
                  navigateToPage("/profile/accessibility");
                }}
              />
              <ProfileSettingsButton
                icon={Button_Info}
                title="App information"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "App information",
                  });
                  navigateToPage("/profile/app-info");
                }}
              />
              <ProfileSettingsButton
                icon={Button_Copyright}
                title="Terms & Conditions"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Terms & Conditions",
                  });
                  openLink(TERMS_AND_CONDITIONS_URL);
                }}
              />
              <ProfileSettingsButton
                icon={Button_Help_Grey60}
                title="Contact support"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Feedback",
                  });
                  contactSupport();
                }}
              />
              <ProfileSettingsButton
                icon={Button_Survey_Grey60}
                title="Take our survey"
                showDisclosure
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Take our survey",
                  });
                  openLink(FEEDBACK_FORM_URL);
                }}
              />
              <ProfileSettingsButton
                icon={Button_Logout}
                title="Sign out"
                buttonClicked={() => {
                  logButtonPressed("profile_more_button_pressed", {
                    button: "Logout",
                  });
                  logout();
                }}
                logout
              />
            </IonList>
          </ProfileSettingsCard>
        </ContentContainer>
      </IonContent>
    </IonPage>
  );
};

export default withRouter(Profile);
