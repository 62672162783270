import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import { CenterContainer, CommentsCard, HeaderTitle } from "parafolio-components";
import { useHistory, useLocation } from "react-router";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import programsData from "../../assets/json/programData.json";
import {
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  IEvidence,
  IUser,
  ProgramData,
  ProgressCheck,
  ProgressCheckApproval,
  ProgressCheckStatus,
  SubmissionButtonState,
  SubmissionState,
} from "../../Interfaces";
import ProgressCheckDescription from "../../components/programs/ProgressCheckDescription";
import ProgressCheckEvidenceButton from "../../components/programs/ProgressCheckEvidenceButton";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import { Icon_Add_Evidence, Icon_Refresh } from "../../assets/images";
import { EventRegister } from "react-native-event-listeners";
import { useRecoilValueLoadable } from "recoil";
import { evidenceAtom, progressCheckDataAtom, userAtom } from "../../state/State";
import _ from "lodash";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import * as EvidenceUtils from "../../utils/evidenceUtils";
import * as ProgramUtils from "../../utils/programUtils";
import SubmitProgressCheckModal from "../../components/programs/SubmitProgressCheckModal";
import { CommentHelpers } from "../../utils/commentHelpers";
import { ProgressCheckService } from "../../controllers/ProgressCheckService";
import EvidenceDisclaimer from "../../components/programs/EvidenceDisclaimer";

const ProgressCheckPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[]>(progressCheckDataAtom);

  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [progressCheck, setProgressCheck] = useState<ProgressCheck | any | null>(null);
  const [progressCheckEvidence, setProgressCheckEvidence] = useState<IEvidence[]>([]);
  const [competences, setCompetences] = useState<CompetenceActivityClass[]>([]);
  const [allEvidenceAdded, setAllEvidenceAdded] = useState<boolean>(false);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[]>([]);

  const [currentProgressCheckData, setCurrentProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);
  const [progressCheckData, setProgressCheckData] = useState<ProgressCheckStatus | null>(null);

  const [submitProgressCheckModalVisible, setSubmitProgressCheckModalVisible] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  }, []);

  useEffect(() => {
    const getProgramData = (): void => {
      if (location.pathname) {
        const pathname: string = location.pathname;
        const progressCheckID = pathname.substring(pathname.lastIndexOf("/") + 1);
        const pathname2 = pathname.replace(`/progress-check/${progressCheckID}`, "");
        const programID = pathname2.substring(pathname2.lastIndexOf("/") + 1);

        const allPrograms = programsData;
        const _program = allPrograms.find((item: any): item is ProgramData => item.ID === programID);

        const allProgressChecks: ProgressCheck[] | any[] = _program?.ProgressChecks || [];
        const _progressCheck: ProgressCheck | undefined = allProgressChecks.find(
          (item: any): item is ProgressCheck => item.ID === progressCheckID
        );

        if (_program) {
          setProgram(_program);
          setProgressChecks(allProgressChecks);
        }

        if (_progressCheck) {
          setProgressCheck(_progressCheck);

          if (_progressCheck["Competence/Activity"]) {
            setCompetences(_progressCheck["Competence/Activity"]);
          }
        }
      }
    };

    getProgramData();
  }, [location.pathname]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      const dataForProgressCheck = dataForProgram?.pCs.find((item) => item.pCId === progressCheck?.ID);

      dataForProgram && setCurrentProgressCheckData(dataForProgram);
      dataForProgressCheck && setProgressCheckData(dataForProgressCheck);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressCheck]);

  useEffect(() => {
    const getEvidenceForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[];

      evidenceForProgressCheck = evidence.contents?.filter((evidence: IEvidence) => {
        const evidenceJSON = evidence.evidenceJSON && JSON.parse(evidence.evidenceJSON);
        const id = evidenceJSON?.programInfo?.progressCheckID || "";

        return id === progressCheck?.ID;
      });

      evidenceForProgressCheck = _.orderBy(evidenceForProgressCheck, "date", "desc");

      setProgressCheckEvidence(evidenceForProgressCheck);
    };

    if (evidence.state === "hasValue" && progressCheck) {
      getEvidenceForProgressCheck();
    }
  }, [evidence, progressCheck]);

  useEffect(() => {
    const checkAllEvidenceAddedForProgressCheck = (): void => {
      let evidenceForProgressCheck: IEvidence[] = _.cloneDeep(progressCheckEvidence);

      evidenceForProgressCheck = evidenceForProgressCheck.filter((item) => {
        const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

        const onHoldReset = evidenceJSON?.OnHoldReset === 1;

        return !onHoldReset && item.draft !== true;
      });

      const allCompetences = _.cloneDeep(competences);

      const progressCheckIDs = evidenceForProgressCheck
        .map((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);

          return evidenceJSON?.programInfo?.progressCheckCompetenceID;
        })
        .sort();

      const competenceIDs = allCompetences.map((item) => item.ID).sort();

      setAllEvidenceAdded(competenceIDs.every((id) => progressCheckIDs.includes(id)));
    };

    if (progressCheckEvidence.length > 0) {
      checkAllEvidenceAddedForProgressCheck();
    }
  }, [competences, progressCheckEvidence]);

  const addEvidencePressed = (competence?: CompetenceActivityClass): void => {
    const progressChecks = program.ProgressChecks ?? [];

    const data = {
      program: program,
      progressCompetences: competences,
      ...(competence && { progressCompetence: competence }),
      progressCheck: progressCheck,
      ...(progressChecks.length > 0 && { progressChecks }),
    };

    EventRegister.emit("evidence/add-progress-check-evidence", data);
  };

  const goToComments = (): void => {
    const programID = program?.ID;
    const progressCheckID = progressCheck?.ID;

    history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}/comments`);
  };

  const evidenceCardPressed = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const programID = program?.ID;
    const progressCheckID = progressCheck?.ID;

    history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}/read/${_evidence.id}`);
  };

  const submitProgressCheck = async (): Promise<void> => {
    try {
      setSubmitting(true);
      let submitted = false;

      submitted = await ProgressCheckService.updateProgressCheck(user.contents, program?.ID, progressCheck?.ID);
      submitted && EventRegister.emit("progress-check/submitted", user.contents);

      setSubmitting(false);
      setSubmitProgressCheckModalVisible(false);
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  const submitButtonPressed = (): void => {
    setSubmitProgressCheckModalVisible(true);
  };

  const getSubmissionCardHeaderText = (progressCheck: ProgressCheck, data: ProgressCheckStatus | null): string => {
    if (data) {
      if (data.submissions.status === SubmissionState.Submitted) {
        return progressCheck.SubmissionWaitingText || "";
      } else if (data.submissions.status === SubmissionState.OnHoldReset) {
        return progressCheck.SubmissionRejectionText || "";
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return progressCheck.SubmissionRejectionText || "";
      }
    }

    return progressCheck.SubmissionHeaderText || "";
  };

  const getSubmissionSidePillText = (data: ProgressCheckStatus | null): string | undefined => {
    if (data) {
      if (data.submissions.status === SubmissionState.OnHoldReset) {
        return "Submission held";
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return "Submission held";
      }
    }

    return undefined;
  };

  const submissionHasError = (data: ProgressCheckStatus | null): boolean | undefined => {
    if (data) {
      if (data.submissions.status === SubmissionState.OnHoldReset) {
        return true;
      } else if (data.submissions.status === SubmissionState.OnHold) {
        return true;
      }
    }

    return undefined;
  };

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref={`/dashboard/program/${program?.ID}`}
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{progressCheck?.Name || ""}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="headerButton" mode="ios" onClick={() => refreshUserProgramData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground">
        <SubmitProgressCheckModal
          cancelPressed={() => setSubmitProgressCheckModalVisible(false)}
          submitPressed={() => submitProgressCheck()}
          visible={submitProgressCheckModalVisible}
          submitting={submitting}
          headerText={progressCheck?.SubmissionModalHeader || ""}
          bodyText={progressCheck?.SubmissionModalBody || ""}
          expand={progressCheck?.Approval === ProgressCheckApproval.ApprovalOO}
        />
        <div className="pb-[96px]">
          {progressCheck && (
            <ProgramInfoCard title={progressCheck.Name}>
              {!ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) &&
                ProgramUtils.checkIfProgressCheckIsLocked(
                  progressCheck,
                  progressChecks,
                  evidence.contents,
                  currentProgressCheckData
                ) && (
                  <div className="mb-[8px]">
                    <EvidenceDisclaimer message={ProgramUtils.getProgressCheckLockedDisclaimerText(progressCheck.ID)} />
                  </div>
                )}
              {progressCheck.Description && !ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
                <ProgressCheckDescription description={progressCheck.Description} />
              )}
              {ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
                <>
                  <ProgressCheckDescription
                    description={ProgramUtils.formatApprovalText(
                      progressCheck.SubmissionApprovedText,
                      progressCheckData?.submissions
                    )}
                  />
                  <ProgramCardButton
                    callToAction
                    buttonText={ProgramUtils.getProgressCheckSubmissionButtonText(progressCheckData?.submissions)}
                    buttonType={ProgramUtils.getProgressCheckSubmissionButtonClass(progressCheckData?.submissions)}
                    buttonTextColor={ProgramUtils.getProgressCheckSubmissionButtonTextClass(
                      progressCheckData?.submissions
                    )}
                    buttonPressed={() => console.log("")}
                    disabled={ProgramUtils.isProgressCheckButtonDisabled(
                      progressCheckData?.submissions,
                      allEvidenceAdded
                    )}
                  />
                </>
              )}
              {ProgramUtils.isProgressCheckCompleted(allEvidenceAdded, progressCheck.Approval) && (
                <>
                  <ProgramCardButton
                    callToAction
                    buttonText="Completed"
                    buttonType="!program-card-button-approved"
                    buttonTextColor="!text-green-text"
                    buttonPressed={() => console.log("")}
                    disabled={true}
                  />
                </>
              )}
              {competences.length > 0 && (
                <div className="mt-[4px]">
                  <div className="flex items-center min-h-[44px] text-[15px] font-bold leading-[1.33] tracking-default text-grey-90 shadow-program-skill shadow-grey-30">
                    {"Required evidence"}
                  </div>
                  {competences.map((competence) => (
                    <ProgressCheckEvidenceButton
                      buttonPressed={() => addEvidencePressed(competence)}
                      competence={competence}
                      disabled={ProgramUtils.checkIfProgressCheckIsLocked(
                        progressCheck,
                        progressChecks,
                        evidence.contents,
                        currentProgressCheckData
                      )}
                      evidenceAdded={EvidenceUtils.checkEvidenceAddedForProgressCheckCompetence(
                        progressCheckEvidence,
                        competence
                      )}
                      key={competence.ID}
                    />
                  ))}
                </div>
              )}
              {!allEvidenceAdded && (
                <ProgramCardButton
                  buttonPressed={() => addEvidencePressed()}
                  buttonText="Add new evidence"
                  icon={Icon_Add_Evidence}
                  disabled={ProgramUtils.checkIfProgressCheckIsLocked(
                    progressCheck,
                    progressChecks,
                    evidence.contents,
                    currentProgressCheckData
                  )}
                />
              )}
            </ProgramInfoCard>
          )}
          {progressCheck?.CommentsSupported && (
            <CenterContainer>
              <CommentsCard
                buttonPressed={() => goToComments()}
                latestComment={CommentHelpers.getLatestComment(progressCheckData?.comments)}
                showNew={CommentHelpers.showNewCommentsCardLabel(
                  user.contents,
                  progressCheckData?.comments,
                  progressCheckData?.commentReadTimes
                )}
                unreadCount={CommentHelpers.getUnreadCommentCount(
                  user.contents,
                  progressCheckData?.comments,
                  progressCheckData?.commentReadTimes
                )}
              />
            </CenterContainer>
          )}
          {progressCheck?.Approval &&
            ProgramUtils.canSubmitProgressCheck(progressCheck.Approval) &&
            !ProgramUtils.isProgressCheckApproved(progressCheckData?.submissions) && (
              <ProgramInfoCard
                title="Submit"
                subTitle={getSubmissionCardHeaderText(progressCheck, progressCheckData)}
                subTitleIsError={submissionHasError(progressCheckData)}
                sidePill={submissionHasError(progressCheckData)}
                sidePillText={getSubmissionSidePillText(progressCheckData)}
              >
                <ProgramCardButton
                  callToAction
                  buttonText={ProgramUtils.getProgressCheckSubmissionButtonText(progressCheckData?.submissions)}
                  buttonType={ProgramUtils.getProgressCheckSubmissionButtonClass(progressCheckData?.submissions)}
                  buttonTextColor={ProgramUtils.getProgressCheckSubmissionButtonTextClass(
                    progressCheckData?.submissions
                  )}
                  buttonPressed={() => submitButtonPressed()}
                  disabled={ProgramUtils.isProgressCheckButtonDisabled(
                    progressCheckData?.submissions,
                    allEvidenceAdded
                  )}
                />
              </ProgramInfoCard>
            )}
          <ProgramInfoCard
            title="Evidence"
            subTitle={
              progressCheckEvidence.length === 0
                ? "Any evidence added for this progress check will appear below."
                : undefined
            }
          >
            {!ProgramUtils.canAddOrEditProgressCheckEvidence(progressCheckData?.submissions) &&
              progressCheckData?.submissions.status !== SubmissionState.OnHoldReset && (
                <div>
                  <EvidenceDisclaimer
                    message={ProgramUtils.getEvidenceDisclaimerText(progressCheckData?.submissions)}
                  />
                </div>
              )}
            {progressCheckEvidence.length > 0 && (
              <div>
                {progressCheckEvidence.map((item) => (
                  <ProgramEvidenceCard
                    key={item.id}
                    buttonPressed={() => evidenceCardPressed(item)}
                    programEvidence={item}
                  />
                ))}
              </div>
            )}
          </ProgramInfoCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ProgressCheckPage;
