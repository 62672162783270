import { IonButton, IonLabel, IonSegment, IonSegmentButton } from "@ionic/react";
import { EventRegister } from "react-native-event-listeners";
import { useRecoilStateLoadable } from "recoil";
import { FirebaseService } from "../../controllers/FirebaseService";
import { IEvidence, ISegmentOptions } from "../../Interfaces";
import { evidenceAtom } from "../../state/State";
import "./HomeComponents.css";
import { useEffect, useState } from "react";
import { IonSegmentCustomEvent, SegmentChangeEventDetail } from "@ionic/core";
import _ from "lodash";
import {
  addDays,
  differenceInCalendarMonths,
  format,
  isBefore,
  isSameDay,
  isSameMonth,
  max,
  min,
  subMonths,
} from "date-fns";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
import { faker } from "@faker-js/faker";
import { differenceInMonths } from "date-fns";

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend);

const EvidenceOverview: React.FC = () => {
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);
  const [period, setPeriod] = useState<number>(ISegmentOptions.ONE_MONTH);
  const [totalEvidenceForPeriod, setTotalEvidenceForPeriod] = useState<number>(0);
  const [monthlyAverage, setMonthlyAverage] = useState<number>(0);
  const [graphLabels, setGraphLabels] = useState<string[]>([]);
  const [graphDates, setGraphDates] = useState<Date[]>([]);
  const [graphData, setGraphData] = useState<number[]>([]);

  useEffect(() => {
    const getDataFromEvidence = (): void => {
      let _evidence: IEvidence[] = _.cloneDeep(evidence.contents) ?? [];
      _evidence = _evidence.filter((item) => item.draft !== true);

      const dates: Date[] = _evidence.map((item) => new Date(item.date));
      const minDate = min(dates) || new Date();
      const maxDate = max(dates) || new Date();

      const months = differenceInCalendarMonths(maxDate, minDate);

      const average = months === 0 ? _evidence.length : _evidence.length / (months + 1);

      setTotalEvidenceForPeriod(_evidence.length);
      setMonthlyAverage(isNaN(average) ? 0 : Math.round(average / 0.5) * 0.5);
    };

    const getLabelsFromEvidence = (): void => {
      let dates: Date[] = [];

      if (period === ISegmentOptions.ONE_MONTH) {
        let date = subMonths(new Date(), 1);

        while (isBefore(date, addDays(new Date(), 1))) {
          dates.push(date);
          date = addDays(date, 1);
        }

        setGraphDates(dates);
        setGraphLabels(dates.map((item) => format(item, "dd/MM")));
      } else {
        for (let i = 0; i < period; i++) {
          dates.push(subMonths(new Date(), i));
        }

        dates.reverse();
        setGraphDates(dates);
        setGraphLabels(dates.map((item) => format(item, "MMM").toUpperCase()));
      }
    };

    if (evidence.state === "hasValue" && evidence.contents) {
      getDataFromEvidence();
      getLabelsFromEvidence();
    }
  }, [evidence, period]);

  useEffect(() => {
    const getGraphData = (): void => {
      let _evidence: IEvidence[] = _.cloneDeep(evidence.contents) ?? [];
      _evidence = _evidence.filter((item) => item.draft !== true);

      let data: number[] = [];

      if (period === ISegmentOptions.ONE_MONTH) {
        for (let i = 0; i < graphDates.length; i++) {
          let array: IEvidence[] = [];

          array = _evidence.filter((item) => isSameDay(graphDates[i], new Date(item.date)));
          data.push(array.length);
        }
      } else {
        for (let i = 0; i < graphDates.length; i++) {
          let array: IEvidence[] = [];

          array = _evidence.filter((item) => isSameMonth(graphDates[i], new Date(item.date)));

          data.push(array.length);
        }
      }

      setGraphData(data);
    };

    if (evidence.state === "hasValue" && evidence.contents && graphDates.length > 0) {
      getGraphData();
    }
  }, [evidence, graphDates, period]);

  const addEvidence = async (): Promise<void> => {
    await FirebaseService.logEvent("add_evidence_pressed", {
      tab: "dashboard",
      type: "empty_evidence_button",
    });

    EventRegister.emit("evidence/empty-add-clicked");
  };

  const changeSegment = async (event: IonSegmentCustomEvent<any>): Promise<void> => {
    try {
      await FirebaseService.logEvent("evidence_overview_period", {
        period: parseInt(`${event.detail.value}` || "6", 10),
      });
    } catch (error) {
      console.log(error);
    }

    setPeriod(parseInt(`${event.detail.value}` || "6", 10));
  };

  return (
    <div className="dashboardCard">
      <div className="dashboardOverviewCardHeader">
        <div className="dashboardCardTitle">{"Evidence overview"}</div>
        <div className="dashboardCardSubtitle">
          {"Track your evidence across months over the last year and see if you're on track to be ready for audit"}
        </div>
      </div>
      {evidence.state === "hasValue" && evidence.contents && evidence.contents?.length > 0 ? (
        <div className="evidenceOverviewGraph">
          <div className="evidenceOverviewTotalContainer">
            <div className="evidenceOverviewTotal">
              <div className="evidenceOverviewTotalTitle">{"Total evidence"}</div>
              <div className="evidenceOverviewTotalValue">{`${totalEvidenceForPeriod}`}</div>
            </div>
            <div className="evidenceOverviewTotalSeparator" />
            <div className="evidenceOverviewTotal">
              <div className="evidenceOverviewAverageTitle">{"Monthly average"}</div>
              <div className="evidenceOverviewAverageValue">{`${monthlyAverage}`}</div>
            </div>
          </div>
          <IonSegment
            className="segmentContainer"
            mode="ios"
            value={`${period}`}
            onIonChange={(event) => changeSegment(event)}
          >
            <IonSegmentButton value={`${ISegmentOptions.ONE_MONTH}`}>
              <IonLabel>{"Month"}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={`${ISegmentOptions.SIX_MONTHS}`}>
              <IonLabel>{"6 months"}</IonLabel>
            </IonSegmentButton>
            <IonSegmentButton value={`${ISegmentOptions.ONE_YEAR}`}>
              <IonLabel>{"Year"}</IonLabel>
            </IonSegmentButton>
          </IonSegment>
          <div className="graphContainer">
            <Bar
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
                scales: {
                  y: {
                    display: true,
                    position: "right",
                    min: 0,
                    ticks: {
                      stepSize: Math.max(...graphData) > 10 ? 2 : 1,
                      autoSkip: true,
                    },
                    max: Math.ceil(Math.max(...graphData, monthlyAverage)) + 1,
                  },
                  x: {
                    ticks: {
                      autoSkip: period === ISegmentOptions.ONE_MONTH,
                      maxTicksLimit: period === ISegmentOptions.ONE_MONTH ? 14 : graphLabels.length,
                    },
                  },
                },
                maintainAspectRatio: false,
              }}
              data={{
                labels: graphLabels,
                datasets: [
                  {
                    label: "Evidence",
                    data: graphLabels.map((_, index) => graphData[index]),
                    backgroundColor: "#48BB78",
                    barPercentage: 0.2,
                  },
                  {
                    hidden: true,
                    data: graphLabels.map((_, index) => monthlyAverage),
                  },
                ],
              }}
              plugins={[
                {
                  id: "draw-trend-overview",
                  afterDraw: (chart: ChartJS) => {
                    const data = chart.getDatasetMeta(1);
                    const average = chart.data.datasets[1].data[0];

                    if (typeof average === "number" && average > 0) {
                      const { ctx } = chart;
                      const startX = chart.scales.x.left;
                      const endX = chart.scales.x.right;
                      const averageY = data.data[0].y;

                      ctx.save();
                      ctx.beginPath();
                      ctx.moveTo(startX, averageY);
                      ctx.lineTo(endX, averageY);
                      ctx.lineWidth = 2;
                      ctx.strokeStyle = "#5A67D8";
                      ctx.stroke();
                      ctx.restore();
                    }
                  },
                },
              ]}
            />
          </div>
        </div>
      ) : (
        <div className="evidenceOverviewEmptyContainer">
          <div className="evidenceOverviewEmptyText">
            {"Add your first piece of evidence to get your portfolio started"}
          </div>
          <IonButton onClick={addEvidence} className="addEvidenceButton">
            <div>{"Add evidence"}</div>
          </IonButton>
        </div>
      )}
    </div>
  );
};

export default EvidenceOverview;
