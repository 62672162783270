import { TTSOptions } from "@capacitor-community/text-to-speech";

export namespace AccessibilityUtils {
  export function getTextToSpeechProperties(text: string, supportedLanguages: { languages: string[] }): TTSOptions {
    text = text.replace(/\n/g, ". ");
    text = text.replace(/\.\./g, ".");
    text = text.replace(/\.\s\./g, ".");

    if (text.startsWith(". ")) {
      text = text.slice(2);
    }

    let textToSpeechProperties: TTSOptions = {
      text: text || "",
      rate: 1.0,
      pitch: 1.0,
      volume: 1.0,
      category: "playback",
    };

    if (supportedLanguages.languages.includes("en-GB")) {
      textToSpeechProperties.lang = "en-GB";
    }

    return textToSpeechProperties;
  }
}
