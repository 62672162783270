import { IonButton, IonButtons, IonContent, IonHeader, IonImg, IonModal, IonToolbar } from "@ionic/react";
import { useEffect, useState } from "react";
import ContentContainer from "../common/ContentContainer";
import DataController from "../../controllers/DataController";
import { IAccessibilitySettings, IInfoModal, IUser } from "../../Interfaces";
import HeaderTitle from "../common/HeaderTitle";
import { Capacitor } from "@capacitor/core";
import DOMPurify from "dompurify";
import { FirebaseService } from "../../controllers/FirebaseService";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { accessibilityAtom, appInfoAtom, deviceInfoAtom, userAtom } from "../../state/State";
import { DeviceInfo } from "@capacitor/device";
import { AppInfo } from "@capacitor/app";
import { useTextToSpeech } from "../../hooks/accessibility/useTextToSpeech";
import { Button_Audio_Play_White, Button_Audio_Stop } from "../../assets/images";
import * as ProgramUtils from "../../utils/programUtils";

const InfoModal = ({ visible, closeModal, body, program }: IInfoModal) => {
  const user = useRecoilValueLoadable<IUser | null>(userAtom);
  const appInfo = useRecoilValue<AppInfo | null>(appInfoAtom);
  const deviceInfo = useRecoilValue<DeviceInfo | null>(deviceInfoAtom);
  const accessibilitySettings = useRecoilValue<IAccessibilitySettings | null>(accessibilityAtom);

  const { handleTTS, playing, stopTTS } = useTextToSpeech("infoModalContainer");

  const contactSupport = async (): Promise<void> => {
    if (user.state === "hasValue" && user.contents) {
      const url = "mailto:apps@class.co.uk";
      const subject = `ParaFolio - ${program?.Trust} - ${program?.Title} support`;

      const body = DataController.supportEmailBody(user.contents.userData, deviceInfo, appInfo);
      await FirebaseService.logEvent("support_link_pressed", {
        link: "contact_support",
        type: "program_module_support",
      });

      window.open(`${url}?subject=${subject}&body=${body}`, "_blank");
    }
  };

  const sanitizeData = (data: string) => ({
    __html: DOMPurify.sanitize(data, {
      ADD_TAGS: ["iframe"],
      ALLOWED_ATTR: [
        "class",
        "width",
        "height",
        "src",
        "title",
        "target",
        "rel",
        "href",
        "title",
        "allow",
        "referrerPolicy",
        "allowFullScreen",
      ],
    }),
  });

  return (
    <IonModal
      id="summaryOfWorkModal"
      isOpen={visible}
      onIonModalWillDismiss={() => {
        stopTTS();
        closeModal();
      }}
      backdropDismiss={true}
      className="fullscreen"
    >
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonButton
              className="cancelButton"
              mode="ios"
              onClick={() => {
                stopTTS();
                closeModal();
              }}
            >
              {"Close"}
            </IonButton>
          </IonButtons>
          <HeaderTitle>{"Information"}</HeaderTitle>
          {accessibilitySettings?.textToSpeech && (
            <IonButtons slot="end">
              <IonButton className="headerButton" mode="ios" onClick={() => handleTTS()}>
                <IonImg src={playing ? Button_Audio_Stop : Button_Audio_Play_White} className="headerIcon" />
              </IonButton>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground whiteBackground">
        <ContentContainer>
          <div className="infoModalContainer" id="infoModalContainer">
            <h1>{`${program?.Title} Information`}</h1>
            {!ProgramUtils.hideHardcodedSupportLine(program?.ID) && (
              <p>
                {"If you need help with the app, "}
                <button className="tipModalLinkButton" onClick={contactSupport}>
                  <div className="tipModalLink">{"please contact support"}</div>
                </button>
                {"."}
              </p>
            )}
            {body && <div className="tipModalText mx-0 mt-0" dangerouslySetInnerHTML={sanitizeData(body)} />}
          </div>
        </ContentContainer>
      </IonContent>
    </IonModal>
  );
};

export default InfoModal;
