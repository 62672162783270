import React from "react";
import { IProgressCheckEvidenceButton } from "../../types/Components";
import { IonItem } from "@ionic/react";

const ProgressCheckEvidenceButton: React.FC<IProgressCheckEvidenceButton> = (props) => {
  return (
    <IonItem
      button={!props.evidenceAdded}
      disabled={props.disabled}
      className="preceptee-item-button flex flex-row !justify-between items-center"
      detail={false}
      onClick={() => !props.evidenceAdded && props.buttonPressed()}
    >
      <div className="min-h-[44px] w-full flex flex-row !justify-between items-center py-[10px]">
        <div className="text-[15px] leading-[1.33] tracking-[-0.3px] text-grey-90">{props.competence.Name}</div>
        <div className="flex ml-[8px]">
          {props.evidenceAdded ? (
            <div className="shrink-0 h-[20px] py-0 px-[8px] bg-[#C6F7C1] rounded-[10px] font-inter text-[13px] font-semibold leading-[1.54] tracking-[-0.05px] text-right text-[#085333]">
              {"Added"}
            </div>
          ) : (
            <div className="font-[15px] leading-[1.33] tracking-[-0.3px] text-cta-blue">{"Add"}</div>
          )}
        </div>
      </div>
    </IonItem>
  );
};

export default ProgressCheckEvidenceButton;
