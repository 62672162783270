import { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  IonBackButton,
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonImg,
  IonPage,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import DataController from "../../controllers/DataController";
import { Capacitor } from "@capacitor/core";
import HeaderTitle from "../../components/common/HeaderTitle";
import {
  Button_Info_White,
  Icon_Add_Evidence,
  Icon_Export_Blue,
  Icon_Pencil,
  Icon_Refresh,
  Image_Completion,
} from "../../assets/images";
import { useHistory, useLocation } from "react-router";
import { useRecoilStateLoadable, useRecoilValue, useRecoilValueLoadable } from "recoil";
import {
  AWSAccessParams,
  AllProgressCheckStatuses,
  CompetenceActivityClass,
  ConfidenceLevelData,
  IClassSubscription,
  IEvidence,
  IExportType,
  IUser,
  IUserProgramCertificate,
  ProgramCertificate,
  ProgramData,
  ProgramSkill,
  ProgressCheck,
  Required,
  UsageData,
} from "../../Interfaces";
import { evidenceAtom, progressCheckDataAtom, userAtom, userProgramsAtom } from "../../state/State";
import EvidenceContainer from "../../components/evidence/EvidenceContainer";
import programsData from "../../assets/json/programData.json";
import ProgramInfoCard from "../../components/home/ProgramInfoCard";
import { EventRegister } from "react-native-event-listeners";
import { format, subYears } from "date-fns";
import AuthenticationController from "../../controllers/AuthenticationController";
import { FirebaseService } from "../../controllers/FirebaseService";
import ExportedPDF from "../../components/pdfExport/ExportedPDF";
import saveAs from "file-saver";
import WriteBlob from "capacitor-blob-writer";
import { Directory } from "@capacitor/filesystem";
import { FileOpener } from "@capacitor-community/file-opener";
import { pdf, Document } from "@react-pdf/renderer";
import AWSService from "../../controllers/AWSService";
import mime from "mime";
import InfoModal from "../../components/programs/InfoModal";
import { ConfidenceLevelGraph, DetailsCard, ProgressCheckButton } from "parafolio-components";
import CompetenceItem from "../../components/programs/CompetenceItem";
import ProgramCardButton from "../../components/programs/ProgramCardButton";
import RequiredSkillCard from "../../components/programs/RequiredSkillCard";
import ProgramRolesDisplay from "../../components/programs/ProgramRolesDisplay";
import ProgramCertificatesDisplay from "../../components/programs/ProgramCertificatesDisplay";
import MandatoryCertificateDisplay from "../../components/programs/MandatoryCertificateDisplay";
import * as ProgramUtils from "../../utils/programUtils";
import ProgramEvidenceCard from "../../components/programs/ProgramEvidenceCard";
import { StringUtils } from "../../utils/stringUtils";
import { CommentHelpers } from "../../utils/commentHelpers";
import { IMandatoryStandard, IOptionalStandard } from "../../Interfaces";
import { PMUsageData, ProgramModule, getUsageDataByUser } from "pf-support-lib";
import NOSOverviewItem from "../../components/programs/NOSOverviewItem";
import DetailsContentCollapsible from "../../components/programs/DetailsContentCollapsible";
import { useProgressCheckData } from "../../hooks/programs/useProgressCheckData";

const ProgramHome: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const [user, setUser] = useRecoilStateLoadable<IUser | null>(userAtom);
  const evidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const allProgressCheckData = useRecoilValueLoadable<AllProgressCheckStatuses[] | null>(progressCheckDataAtom);

  const [program, setProgram] = useState<ProgramData | any | null>(null);
  const [requiredSkills, setRequiredSkills] = useState<ProgramSkill[] | any[]>([]);
  const [certificates, setCertificates] = useState<ProgramCertificate[] | any[]>([]);
  const [competences, setCompetences] = useState<CompetenceActivityClass[] | any[]>([]);
  const [progressChecks, setProgressChecks] = useState<ProgressCheck[] | any[]>([]);
  const [currentProgressCheckData, setCurrentProgressCheckData] = useState<AllProgressCheckStatuses | null>(null);

  const [infoModalVisible, setInfoModalVisible] = useState<boolean>(false);
  const [infoBody, setInfoBody] = useState<string | null>(null);

  const [isSetup, setIsSetup] = useState<boolean>(false);
  const [userRoles, setUserRoles] = useState<string[]>([]);

  const [exportingEvidence, setExportingEvidence] = useState<boolean>(false);
  const [currentExportType, setCurrentExportType] = useState<string>("");

  const [programEvidence, setProgramEvidence] = useState<IEvidence[]>([]);
  const [programCertificates, setProgramCertificates] = useState<IUserProgramCertificate[] | any[]>([]);

  const [viewingCertificate, setViewingCertificate] = useState<boolean>(false);
  const [viewingCertificateFilename, setViewingCertificateFilename] = useState<string>("");

  const [refreshingData, setRefreshingData] = useState<boolean>(false);

  const [usageData, setUsageData] = useState<UsageData[]>([]);

  const userPrograms = useRecoilValue<ProgramData[] | any[]>(userProgramsAtom);

  const { programProgressCheckData } = useProgressCheckData(program?.ID);

  const confidenceLevelData: ConfidenceLevelData | null = useMemo(() => {
    const confidenceLevelEvidence = ProgramUtils.getConfidenceLevelEvidence(programEvidence);
    let mappedData = ProgramUtils.mapConfidenceLevelEvidence(confidenceLevelEvidence);

    return mappedData;
  }, [programEvidence]);

  const infoClicked = useCallback((): void => {
    FirebaseService.logEvent("program_info_pressed", {
      program: program?.Name,
    });

    setInfoModalVisible(true);
  }, [program]);

  useEffect(() => {
    EventRegister.addEventListener("program/data-refreshed", () => setRefreshingData(false));

    return () => {
      EventRegister.removeEventListener("program/data-refreshed");
    };
  }, []);

  useEffect(() => {
    const getInfoDismissed = async (): Promise<void> => {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      if (programSubscription && programSubscription.App_Data) {
        let json: any = {};

        try {
          json = JSON.parse(programSubscription.App_Data);
        } catch (error) {
          console.log(error);
        }

        // Open disclaimer if the user hasn't seen it before and the Program-Module has a disclaimer
        if (!json[`${program.ID}`]?.infoDismissed && program?.PopupDisclaimer) {
          setTimeout(() => {
            infoClicked();
          }, 500);
        }
      }
    };

    if (user.state === "hasValue" && program) {
      getInfoDismissed();
    }
  }, [infoClicked, program, user]);

  useEffect(() => {
    const getProgram = (): void => {
      if (location.pathname) {
        const pathname: string = location.pathname;
        const programID = pathname.substring(pathname.lastIndexOf("/") + 1);

        const allPrograms = programsData;
        const _program = allPrograms.find((item: any): item is ProgramData => item.ID === programID);

        if (_program) {
          setProgram(_program);
          const info = _program.PopupDisclaimer;

          if (info) {
            setInfoBody(info);
          }

          let evidenceForProgram: IEvidence[] = ProgramUtils.getEvidenceForProgramName(
            evidence.contents,
            _program?.Name
          );

          setProgramEvidence(evidenceForProgram);

          const allCompetences: CompetenceActivityClass[] | any[] = _program?.CompetenceActivity || [];

          if (allCompetences.length > 0) {
            const required = ProgramUtils.getAllRequiredSkillsForProgram(allCompetences);

            if (required) {
              setRequiredSkills(required);
            }
          }
        }
      }
    };

    getProgram();
  }, [evidence, location.pathname]);

  useEffect(() => {
    const getProgressCheckData = (): void => {
      const dataForProgram: AllProgressCheckStatuses = allProgressCheckData.contents?.find(
        (item: any): item is AllProgressCheckStatuses => item.programID === program?.ID
      );

      dataForProgram && setCurrentProgressCheckData(dataForProgram);
    };

    if (allProgressCheckData.state === "hasValue") {
      getProgressCheckData();
    }
  }, [allProgressCheckData, program, progressChecks]);

  useEffect(() => {
    const programOrder = (item: IUserProgramCertificate): number => {
      const certificateName = item?.certificate?.certificateType;
      const mappedCertificate = program?.Certificates?.find(
        (_cert: ProgramCertificate) => _cert.Name.toUpperCase() === certificateName?.toUpperCase()
      );

      return mappedCertificate?.Required === Required.Mandatory ? 2 : 1;
    };

    const getCertificateInfo = (): void => {
      if (program) {
        const competences = program.CompetenceActivity || [];

        let array = competences
          .filter((item: any): item is CompetenceActivityClass => item && item.Certificates !== undefined)
          .map((item: CompetenceActivityClass) => item && item.Certificates);
        let _certificates = array.filter((item: ProgramCertificate) => item !== undefined);

        _certificates = _certificates.slice(0, 3);
        setCertificates(_certificates);
      }
    };

    const getUserProgramCertificates = (): void => {
      if (user.contents) {
        let _programCertificates = user.contents?.programCertificates || [];
        _programCertificates = _programCertificates.filter(
          (item: IUserProgramCertificate) => item.programData.programName === program?.Name
        );

        setProgramCertificates(
          _.orderBy(_programCertificates, [programOrder, (item) => item.certificate.certificateType], ["desc", "asc"])
        );
      }
    };

    if (user.state === "hasValue" && program) {
      getCertificateInfo();
      getUserProgramCertificates();
    }
  }, [program, user]);

  useEffect(() => {
    const getCompetences = (): void => {
      if (program) {
        const _competences = program.CompetenceActivity || [];

        if (_competences) {
          setCompetences(_competences);
        }
      }
    };

    const getProgressChecks = (): void => {
      if (program) {
        const _progressChecks = program.ProgressChecks || [];

        if (_progressChecks) {
          setProgressChecks(_progressChecks);
        }
      }
    };

    getCompetences();
    getProgressChecks();
  }, [program]);

  useEffect(() => {
    const checkIsSetup = (): void => {
      const roles = user.state === "hasValue" && _.cloneDeep(user.contents?.programRoles);

      const hasRolesSetup = typeof roles !== "undefined";

      if (roles) {
        const programRoles = roles.find((item) => item.programName === program.Name);

        if (programRoles) {
          if (ProgramUtils.usingDescriptiveProgramRolesFormat(program.ID)) {
            const roles = programRoles.programRoles.map((r) => r.roleName);
            setUserRoles(roles);
          } else {
            setUserRoles(programRoles.programRoles);
          }
        }
      }

      setIsSetup(hasRolesSetup);
    };

    if (user.state === "hasValue" && user.contents && program) {
      checkIsSetup();
    }
  }, [user, program]);

  useEffect(() => {
    const calculateUsageData = () => {
      if (!program || !ProgramUtils.shouldCalculateUsageData(program.ID)) return;

      const eprr: any = programsData.find((program) => program.ID === "LR0HYZYWY6JQK");
      const pm = new ProgramModule(eprr);

      const contactID = Number(user.contents.userData.contactID);
      const roleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);

      const pMUsageData = new PMUsageData(eprr, []);

      const filteredEvidence = programEvidence.filter((ev) => !ev.draft) as [];

      const results = pMUsageData.getUsageDataByUser(pm, contactID, roleID, filteredEvidence);
      setUsageData(results);
    };

    calculateUsageData();
  }, [programEvidence]);

  const closeInfoModal = async (): Promise<void> => {
    try {
      const subscriptions = user.contents?.userData.subscriptions || [];
      const programSubscriptionIDs = program?.Subscription || [];

      const programSubscription: IClassSubscription = subscriptions.find((item: IClassSubscription | any) => {
        const subscriptionPrograms: string[] = item.ParaFolioPrograms;

        return (
          subscriptionPrograms?.some((_sub) => program?.Subscription.includes(_sub)) ||
          programSubscriptionIDs.includes(`${item.SubscriptionID}`)
        );
      });

      if (programSubscription) {
        if (programSubscription.App_Data) {
          let json: any = {};

          try {
            json = JSON.parse(programSubscription.App_Data);
          } catch (error) {
            console.log(error);
          }

          if (!json[`${program.ID}`]?.infoDismissed) {
            const data = {
              ...json,
              [`${program.ID}`]: {
                startDate: json[`${program.ID}`]?.startDate,
                infoDismissed: true,
              },
            };

            const success = await AuthenticationController.appendSubAppData(
              user.contents?.userData.contactID,
              user.contents?.token!,
              programSubscription.SubscriberID,
              data
            );

            if (success) {
              let userToSave = await AuthenticationController.getUserData(user.contents);

              if (userToSave) {
                setUser(userToSave);
              }
            }
          }
        } else {
          let data = {
            [`${program.ID}`]: {
              infoDismissed: true,
            },
          };

          const success = await AuthenticationController.appendSubAppData(
            user.contents?.userData.contactID,
            user.contents?.token!,
            programSubscription.SubscriberID,
            data
          );

          if (success) {
            let userToSave = await AuthenticationController.getUserData(user.contents);

            if (userToSave) {
              setUser(userToSave);
            }
          }
        }
      }

      setInfoModalVisible(false);
    } catch (error) {
      console.log(error);
      setInfoModalVisible(false);
    }
  };

  const addProgramEvidence = (): void => {
    const evidenceDefinitions = program["Evidence Definitions"] || [];
    const mandatoryStandards = ProgramUtils.getMandatoryStandards(user.contents, program);
    const optionalStandards = ProgramUtils.getOptionalStandards(user.contents, program);

    const data = {
      program: program,
      ...(competences.length > 0 && { competences }),
      ...(evidenceDefinitions.length > 0 && {
        evidenceDefinitions: evidenceDefinitions[0],
      }),
      ...(progressChecks.length > 0 && { progressChecks }),
      ...(mandatoryStandards.length > 0 && { mandatoryStandards }),
      ...(optionalStandards.length > 0 && { optionalStandards }),
      skill: null,
    };

    FirebaseService.logEvent("program_add_enhanced_evidence", {
      program: program?.Name,
    });

    EventRegister.emit("evidence/add-program-evidence", data);
  };

  const addRequiredSkillEvidence = (competence: CompetenceActivityClass, skill: ProgramSkill): void => {
    const data = {
      program: program,
      competences: competences,
      competence: competence,
      skill: skill,
    };

    FirebaseService.logEvent("program_required_skill_evidence", {
      program: program?.Name,
      competence: competence?.Name,
      skill: skill?.Name,
    });

    EventRegister.emit("evidence/add-skill-evidence", data);
  };

  const goToEnhancedSkill = (item: CompetenceActivityClass): void => {
    const programID = program?.ID;
    const skillID = item.ID;

    FirebaseService.logEvent("program_competence_pressed", {
      program: program?.Name,
      competence: item.Name,
    });

    history.push(`/dashboard/program/${programID}/${skillID}`);
  };

  const goToProgressCheck = (item: ProgressCheck): void => {
    const programID = program?.ID;
    const progressCheckID = item.ID;

    FirebaseService.logEvent("program_progress_check_pressed", {
      program: program?.Name,
      progressCheck: item.Name,
    });

    history.push(`/dashboard/program/${programID}/progress-check/${progressCheckID}`);
  };

  const setupDetails = (): void => {
    const programID = program?.ID;

    FirebaseService.logEvent("program_setup_pressed", {
      program: program?.Name,
    });

    history.push(`/dashboard/program/${programID}/details`);
  };

  const logDocumentExport = async (pages: number): Promise<void> => {
    await FirebaseService.logEvent("evidence_exported", { pages });
  };

  const generatePdfDocument = useCallback(
    async (exportType: string, program?: ProgramData): Promise<void> => {
      try {
        const doc = pdf();

        let evidenceToExport: IEvidence[] = [];

        // All evidence for the specific program selected - e.g. HART, CHUB, etc.
        let copy = _.cloneDeep(programEvidence);

        evidenceToExport = _.sortBy(copy, (item) => new Date(item.date).getTime()).reverse();

        evidenceToExport = evidenceToExport.filter((item: IEvidence) => {
          const evidenceJSON = item.evidenceJSON && JSON.parse(item.evidenceJSON);
          const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

          return onHoldReset !== 1 && item.draft !== true;
        });

        doc.updateContainer(
          <ExportedPDF
            evidence={evidenceToExport}
            user={user.contents}
            isHCPC={exportType === IExportType.HCPC}
            summaryOfWork={
              exportType === IExportType.HCPC && user.state === "hasValue" ? user.contents?.summaryOfWork : undefined
            }
            personalStatement={
              exportType === IExportType.HCPC && user.state === "hasValue"
                ? user.contents?.personalStatement
                : undefined
            }
            isMajorIncident={exportType === IExportType.MAJOR_INCIDENT}
            program={program}
            usageData={usageData}
            userPrograms={userPrograms}
            programProgressCheckData={programProgressCheckData}
          />
        );

        doc.on("change", async () => {
          if (doc.container.document && doc.container.document.children.length > 0) {
            const blob = await doc.toBlob();

            try {
              await logDocumentExport(doc.container.document.children.length);
            } catch (error) {
              console.log(error);
            }

            const username = user.contents.userData.userName?.replace(/\s/gi, "-") || "Untitled";
            const programName = program?.Name?.replace(/\s/gi, "-") || "Program";
            const filename = `${username}-${programName}-Portfolio-${format(new Date(), "dd-MM-yyyy")}`;

            if (await DataController.isWebAsync()) {
              saveAs(blob, filename.trim().length > 0 ? filename.trim() : "Untitled");
            } else {
              const filePath = await WriteBlob({
                path: `ParaFolio/Exports/${DataController.getFolderFromExportType(exportType)}/${filename.trim().length > 0 ? filename.trim() : "Untitled"}.pdf`,
                blob,
                directory: Directory.Library,
                recursive: true,
              });

              FileOpener.open({
                filePath,
              });
            }

            doc.updateContainer(<Document />);
            setExportingEvidence(false);
            setCurrentExportType("");
          }
        });
      } catch (error) {
        console.log(error);
        setExportingEvidence(false);
        setCurrentExportType("");
      }
    },
    [programEvidence, user, programProgressCheckData]
  );

  const exportPressed = useCallback(
    async (exportType: string, program: ProgramData): Promise<void> => {
      try {
        if (user.state === "hasValue" && user.contents) {
          setExportingEvidence(true);
          setCurrentExportType(exportType);
          await generatePdfDocument(exportType, program);
        }
      } catch (error) {
        console.log(error);
        window.alert("An error occurred while exporting, please try again.");
        setExportingEvidence(false);
        setCurrentExportType("");
      }
    },
    [generatePdfDocument, user]
  );

  const evidenceCardClicked = (item: IEvidence): void => {
    let _evidence = _.pickBy(_.cloneDeep(item), _.identity);

    const programID = program?.ID;

    history.push(`/dashboard/program/${programID}/read/${_evidence.id}`);
  };

  const openCertificate = async (item: IUserProgramCertificate): Promise<void> => {
    try {
      setViewingCertificate(true);
      setViewingCertificateFilename(item.certificate?.name || "");

      const accessParams: AWSAccessParams = await AWSService.generateSTSToken(user.contents);

      const file = await AWSService.openCertificateFromServer(user.contents, accessParams, item.certificate);

      if (await DataController.isWebAsync()) {
        const url = window.URL.createObjectURL(
          new Blob([new Uint8Array(file.Body.data)], {
            type: file.ContentType,
          })
        );
        window.open(url, "_blank");
      } else {
        const blob = new Blob([new Uint8Array(file.Body.data)], {
          type: file.ContentType,
        });

        const filePath = await WriteBlob({
          path: `ParaFolio/Certificates/${item.certificate?.name}.${mime.getExtension(file.ContentType)}`,
          blob,
          directory: Directory.Library,
          recursive: true,
        });

        FileOpener.open({
          filePath,
        });
      }

      setViewingCertificate(false);
      setViewingCertificateFilename("");
    } catch (error) {
      console.log(error);
      window.alert("An error occurred when opening certificate");
      setViewingCertificate(false);
      setViewingCertificateFilename("");
    }
  };

  const getCurrentProgressCheckData = (allData: AllProgressCheckStatuses | null, check: ProgressCheck) => {
    if (allData) {
      const data = allData.pCs.find((item) => item.pCId === check.ID);

      return data;
    }

    return;
  };

  const refreshUserProgramData = (): void => {
    setRefreshingData(true);

    EventRegister.emit("program/refresh-button", user.contents);
  };

  const renderMandatoryStandards = () => {
    if (user.state === "hasValue") {
      const selectedRoleID = ProgramUtils.getSelectedRoleIdForNWASICProgram(user.contents);
      const mandatoryStandards = ProgramUtils.getMandatoryStandardsFromProgram(program, selectedRoleID);

      if (mandatoryStandards && mandatoryStandards.length > 0) {
        return (
          <>
            <div className="text-acc-13px font-acc-400 text-grey-90">Mandatory Standards</div>
            {mandatoryStandards.map((standard) => {
              const usageResult = usageData.find((item) => item.compId === standard.id);
              const percentage = usageResult ? Number(usageResult.percentage) : 0;
              return (
                <NOSOverviewItem
                  key={standard.id}
                  title={standard.name}
                  subtitle={`${retrieveEvidenceCount(standard.id)} evidence`}
                  description={standard.description}
                  percentage={Math.floor(percentage)}
                  buttonPressed={() => {
                    history.push(`/dashboard/program/${program?.ID!}/standard/${standard.id}`);
                  }}
                />
              );
            })}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const renderOptionalStandards = () => {
    if (user.state === "hasValue") {
      const optionalStandards = ProgramUtils.getOptionalStandardsFromUser(user.contents, program);

      if (optionalStandards && optionalStandards.length > 0) {
        return (
          <>
            <div className="text-acc-13px font-acc-400 text-grey-90 mt-5">Optional Standards</div>
            {optionalStandards.map((standard) => {
              const usageResult = usageData.find((item) => item.compId === standard.id);
              const percentage = usageResult ? Number(usageResult.percentage) : 0;
              return (
                <NOSOverviewItem
                  key={standard.id}
                  title={standard.name}
                  subtitle={`${retrieveEvidenceCount(standard.id)} evidence`}
                  description={standard.description}
                  percentage={Math.floor(percentage)}
                  buttonPressed={() => {
                    history.push(`/dashboard/program/${program?.ID!}/standard/${standard.id}`);
                  }}
                />
              );
            })}
          </>
        );
      } else {
        return null;
      }
    } else {
      return null;
    }
  };

  const retrieveEvidenceCount = (idToMatch: string) => {
    let evidenceCount = 0;
    const filteredEvidece = programEvidence
      .filter((item) => !item.draft) // filter out drafts
      .filter((item) => new Date(item.date) >= subYears(new Date(), 2)); // filter out expired evidence
    filteredEvidece.forEach((evidence) => {
      try {
        const parsed = JSON.parse(evidence.evidenceJSON);
        if (Array.from(parsed.Comps).some((item: any) => item.id === idToMatch)) evidenceCount += 1;
      } catch (error) {
        console.log("Failed to update evidence count");
      }
    });

    return evidenceCount;
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar
          mode="ios"
          className="navBar"
          style={{
            maxWidth: DataController.isWeb() ? 980 : undefined,
            height: Capacitor.getPlatform() === "android" ? "54px" : "unset",
          }}
        >
          <IonButtons slot="start">
            <IonBackButton
              className="headerBackButtons"
              defaultHref="/dashboard"
              text={DataController.getBackIconText()}
              icon={DataController.getBackIconType()}
              style={{
                marginLeft: Capacitor.getPlatform() === "android" ? 8 : 0,
                "--icon-font-size": Capacitor.getPlatform() === "android" ? "24px" : "30px",
              }}
            />
          </IonButtons>
          <HeaderTitle>{program?.["Short Title"] || ""}</HeaderTitle>
          <IonButtons slot="end">
            <IonButton className="headerButton" mode="ios" onClick={() => refreshUserProgramData()}>
              {refreshingData ? (
                <IonSpinner className="w-[44px] h-[20px] text-white" />
              ) : (
                <IonImg src={Icon_Refresh} className="headerIcon" />
              )}
            </IonButton>
            {program?.PopupDisclaimer && (
              <IonButton className="headerButton" mode="ios" onClick={() => infoClicked()}>
                <IonImg src={Button_Info_White} className="headerIcon" />
              </IonButton>
            )}
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent className="pageBackground">
        <InfoModal visible={infoModalVisible} closeModal={() => closeInfoModal()} body={infoBody} program={program} />
        <EvidenceContainer style={{ paddingTop: 0, alignSelf: "center" }}>
          {ProgramUtils.isPreceptorship(program?.ID) && (
            <>
              <DetailsCard
                isPreceptor={false}
                name={user.contents?.name}
                email={user.contents?.email}
                preceptors={ProgramUtils.mapPreceptorNames(currentProgressCheckData?.mentors)}
                region={ProgramUtils.mapRegionNames(user.contents?.userData.Regions)}
                ESRNumber={ProgramUtils.mapESRNumber(program, user.contents?.subscriptions)}
                showExportButton={programEvidence.length > 0}
                isExporting={exportingEvidence}
                showBetaLabel
                exportPressed={() => exportPressed(IExportType.PROGRAM, program)}
              />
            </>
          )}
          {ProgramUtils.isPreceptorship(program?.ID) &&
            ProgramUtils.preceptorshipComplete(
              progressChecks,
              allProgressCheckData.contents,
              evidence.contents,
              program?.ID
            ) && (
              <ProgramInfoCard
                title="Congratulations"
                subTitle="You have completed your Preceptorship! Your evidence will be accessible from here for future reference. Please export your Preceptorship for your own records."
              >
                <IonImg className="self-center w-[160px] h-[112px]" src={Image_Completion} />
              </ProgramInfoCard>
            )}
          {ProgramUtils.hasSetup(program?.ID) && (
            <>
              {isSetup ? (
                ProgramUtils.useDetailsCardInsteadOfProgramInfoCard(program.ID) ? (
                  <div className="accountCard">
                    <div className="accountCardInner">
                      <DetailsContentCollapsible
                        title={user && user.state === "hasValue" ? user.contents?.name! : ""}
                        programID={program.ID}
                      >
                        <ProgramRolesDisplay userRoles={userRoles} programId={program.ID} />
                        {programCertificates.map((certificate: IUserProgramCertificate, index) => (
                          <ProgramCertificatesDisplay
                            certificate={certificate}
                            buttonPressed={() => openCertificate(certificate)}
                            key={certificate.certificate?.certificateType}
                            viewingCertificate={viewingCertificate}
                            viewingFilename={viewingCertificateFilename}
                          />
                        ))}
                        <div className="mt-[8px] mb-[4px]">
                          <div className="text-acc-13px leading-[1.54] tracking-[-0.1px] text-grey-70">{"Area"}</div>
                          <div className="text-acc-17px leading-[1.53] tracking-[-0.3px] text-grey-90">
                            {ProgramUtils.getEprrArea(user.contents?.programRoles)}
                          </div>
                        </div>
                        <div className="flex mt-3 gap-2">
                          <IonButton
                            className="flex-1 program-card-button h-[50px]"
                            onClick={() => exportPressed(IExportType.PROGRAM, program)}
                          >
                            {exportingEvidence && currentExportType === IExportType.PROGRAM ? (
                              <IonSpinner className="text-cta-blue" />
                            ) : (
                              <>
                                <IonImg className="w-[40px] h-[40px]" src={Icon_Export_Blue} />
                                <div className="text-[16px] font-semibold text-cta-blue">Export</div>
                              </>
                            )}
                          </IonButton>
                          <IonButton className="flex-1 program-card-button h-[50px]" onClick={() => setupDetails()}>
                            <IonImg className="w-[24px] h-[24px] mr-1" src={Icon_Pencil} />
                            <div className="text-[16px] font-semibold text-cta-blue">Edit</div>
                          </IonButton>
                        </div>
                      </DetailsContentCollapsible>
                    </div>
                  </div>
                ) : (
                  <ProgramInfoCard
                    title={user ? `${user.state === "hasValue" ? user.contents?.name : ""}` : "Your details"}
                    subTitle={user ? (user.state === "hasValue" ? user.contents?.email : "") : undefined}
                    sideButton
                    sideButtonText="View details"
                    buttonClicked={() => setupDetails()}
                  >
                    {program?.Certificates?.length > 0 &&
                      program?.Certificates?.map((certificate: ProgramCertificate) => (
                        <MandatoryCertificateDisplay
                          buttonPressed={() => setupDetails()}
                          certificate={certificate}
                          key={certificate.Name}
                          programCertificates={programCertificates}
                        />
                      ))}
                    {programCertificates.map((certificate: IUserProgramCertificate, index) => (
                      <ProgramCertificatesDisplay
                        certificate={certificate}
                        buttonPressed={() => openCertificate(certificate)}
                        key={certificate.certificate?.certificateType}
                        viewingCertificate={viewingCertificate}
                        viewingFilename={viewingCertificateFilename}
                      />
                    ))}
                    <ProgramRolesDisplay userRoles={userRoles} programId={program.ID} />
                    <ProgramCardButton
                      buttonPressed={() => exportPressed(IExportType.PROGRAM, program)}
                      buttonText={`Export ${program.Title}`}
                    >
                      {exportingEvidence && currentExportType === IExportType.PROGRAM ? (
                        <IonSpinner className="text-cta-blue" />
                      ) : (
                        <div className="text-[16px] font-semibold leading-[1.5] tracking-default text-center text-cta-blue">
                          {`Export ${program.Title}`}
                        </div>
                      )}
                    </ProgramCardButton>
                  </ProgramInfoCard>
                )
              ) : (
                <ProgramInfoCard title="Set up your details" subTitle={program?.DetailsDescription || undefined}>
                  <ProgramCardButton buttonPressed={() => setupDetails()} buttonText="Set up details" callToAction />
                </ProgramInfoCard>
              )}
            </>
          )}
          {ProgramUtils.showMandatoryStandardsInProgramHomePage(program?.ID) && (
            <ProgramInfoCard
              title="NOS Overview"
              subTitle={
                isSetup
                  ? ""
                  : "After selecting your role, your mandatory standards will appear here. You will also be asked to select 2 of 6 optional standards to achieve as part of your profile."
              }
            >
              {renderMandatoryStandards()}
              {renderOptionalStandards()}
              {/* <pre>{JSON.stringify(user.contents.programRoles, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(user.contents, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(userRoles, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(usageData, null, 2)}</pre> */}
            </ProgramInfoCard>
          )}
          {competences?.length > 0 && (
            <ProgramInfoCard
              title="Enhanced Skills"
              subTitle="Add evidence of your CPD for these enhanced skills to ensure you pass recertification"
            >
              {competences.map((competence: CompetenceActivityClass) => (
                <CompetenceItem
                  key={competence.ID}
                  buttonPressed={() => goToEnhancedSkill(competence)}
                  competence={competence}
                  certificates={certificates}
                  programName={program.Name}
                  programCertificates={programCertificates}
                />
              ))}
              <ProgramCardButton
                buttonPressed={() => addProgramEvidence()}
                buttonText="Add new evidence"
                icon={Icon_Add_Evidence}
              />
            </ProgramInfoCard>
          )}
          {progressChecks.length > 0 && (
            <ProgramInfoCard
              title="Progress checks"
              subTitle="Please complete these in order as you progress through your Preceptorship"
            >
              {progressChecks.map((progressCheck: ProgressCheck) => (
                <ProgressCheckButton
                  key={progressCheck.ID}
                  title={progressCheck.Name}
                  buttonPressed={() => goToProgressCheck(progressCheck)}
                  evidenceCount={ProgramUtils.getEvidenceCountForProgressCheck(programEvidence, progressCheck.ID)}
                  newCommentCount={CommentHelpers.getCommentCountForProgressCheckButton(
                    user.contents,
                    currentProgressCheckData,
                    progressCheck.ID
                  )}
                  progressLabel={ProgramUtils.getProgressCheckLabelInformation(
                    progressCheck,
                    getCurrentProgressCheckData(currentProgressCheckData, progressCheck),
                    programEvidence,
                    progressChecks,
                    currentProgressCheckData
                  )}
                />
              ))}
            </ProgramInfoCard>
          )}
          {ProgramUtils.isPreceptorship(program?.ID) && (
            <ProgramInfoCard
              title="Confidence levels"
              subTitle={StringUtils.getConfidenceLevelsHeader(confidenceLevelData)}
            >
              {confidenceLevelData && <ConfidenceLevelGraph data={confidenceLevelData} />}
            </ProgramInfoCard>
          )}
          {requiredSkills?.map((skill: ProgramSkill) => (
            <RequiredSkillCard
              buttonPressed={(competence) => addRequiredSkillEvidence(competence, skill)}
              competences={competences}
              key={skill.ID}
              programEvidence={programEvidence}
              skill={skill}
            />
          ))}
          {ProgramUtils.showEvidenceBlock(program?.ID, user.contents) && (
            <ProgramInfoCard
              title="Evidence"
              style={{ paddingTop: 14, paddingBottom: 8 }}
              subTitle={
                programEvidence.length === 0 ? `Any evidence added as ${program.Title} will appear below.` : undefined
              }
            >
              <ProgramCardButton
                buttonPressed={() => addProgramEvidence()}
                buttonText="Add new evidence"
                icon={Icon_Add_Evidence}
              />
              {programEvidence.length > 0 && (
                <div>
                  {programEvidence.map((item) => (
                    <ProgramEvidenceCard
                      key={item.id}
                      buttonPressed={() => evidenceCardClicked(item)}
                      programEvidence={item}
                      program={program}
                    />
                  ))}
                </div>
              )}
            </ProgramInfoCard>
          )}
        </EvidenceContainer>
      </IonContent>
    </IonPage>
  );
};

export default ProgramHome;
