import { IonApp, IonContent, IonSpinner, setupIonicReact } from "@ionic/react";
import { ErrorBoundary } from "react-error-boundary";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Parafolio component library required CSS */
import "parafolio-components/dist/style.css";

/* Theme variables */
import "./theme/variables.css";

import "./App.css";
import { RecoilRoot } from "recoil";
import { Suspense, useEffect } from "react";
import PortfolioPlus from "./PortfolioPlus";
import { FirebaseService } from "./controllers/FirebaseService";
import DataController from "./controllers/DataController";
import { StatusBar } from "@capacitor/status-bar";
import { NavigationBar } from "@hugotomazi/capacitor-navigation-bar";
import { SplashScreen } from "@capacitor/splash-screen";
import RemoteDataController from "./controllers/RemoteDataController";
import { PushNotifications } from "@capacitor/push-notifications";
import { Subscriptions } from "capacitor-subscriptions";
import { BUNDLE_ID, GOOGLE_VERIFY_ENDPOINT } from "./Constants";

setupIonicReact({});

const App: React.FC = () => {
  useEffect(() => {
    const firebaseSetup = async (): Promise<void> => {
      if (await DataController.isWebAsync()) {
        await FirebaseService.initialize();
      }
    };

    const notificationListenerSetup = async (): Promise<void> => {
      await PushNotifications.addListener("registration", (token) => {
        // console.log('FCM token: ', token.value);
      });

      await PushNotifications.addListener("registrationError", (error) => {
        console.log("Registration error: ", error.error);
        RemoteDataController.logError(error.error);
      });

      await PushNotifications.addListener("pushNotificationReceived", (notification) => {
        console.log("Push notification received: ", notification);
      });
    };

    const notificationSetup = async (): Promise<void> => {
      if (!(await DataController.isWebAsync())) {
        // console.log(await DataController.isWebAsync());
        try {
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive !== "granted") {
            throw new Error("User denied notification permissions!");
          }

          await notificationListenerSetup();
          await PushNotifications.register();
        } catch (error: any) {
          console.log(error);
          RemoteDataController.logError(error);
        }
      }
    };

    firebaseSetup();
    notificationSetup();
  }, []);

  useEffect(() => {
    Subscriptions.setGoogleVerificationDetails({
      googleVerifyEndpoint: GOOGLE_VERIFY_ENDPOINT,
      bid: BUNDLE_ID,
    });
  }, []);

  useEffect(() => {
    const setNavbarColors = async (): Promise<void> => {
      if (!(await DataController.isWebAsync())) {
        try {
          StatusBar.setOverlaysWebView({ overlay: false });
          StatusBar.setBackgroundColor({ color: "#026F8A" });
          NavigationBar.setColor({ color: "#026F8A" });
        } catch (error) {}
      }
    };

    setNavbarColors();
  }, []);

  useEffect(() => {
    const hideSplashScreen = (): void => {
      try {
        SplashScreen.hide();
      } catch (error) {
        console.log(error);
      }
    };

    hideSplashScreen();
  }, []);

  return (
    <RecoilRoot>
      <IonApp>
        <Suspense
          fallback={
            <div className="loadingScreen">
              <IonSpinner className="loadingScreenSpinner" />
            </div>
          }
        >
          <ErrorBoundary
            fallback={<IonContent className="ion-text-center ion-padding">{"Oops, an error has occurred!"}</IonContent>}
          >
            <PortfolioPlus />
          </ErrorBoundary>
        </Suspense>
      </IonApp>
    </RecoilRoot>
  );
};

export default App;
