import React, { useEffect, useState } from "react";
import "../../theme/tailwind.css";
import { IRequiredSkillCard } from "../../types/Components";
import ProgramInfoCard from "../home/ProgramInfoCard";
import { useRecoilValueLoadable } from "recoil";
import { CompetenceActivityClass, IEvidence } from "../../Interfaces";
import { evidenceAtom } from "../../state/State";
import ProgramCardButton from "./ProgramCardButton";
import { Icon_Add_Evidence } from "../../assets/images";
import { isSameMonth } from "date-fns";

const RequiredSkillCard: React.FC<IRequiredSkillCard> = (props) => {
  const allEvidence = useRecoilValueLoadable<IEvidence[] | null>(evidenceAtom);
  const [count, setCount] = useState<number>(0);
  const [total, setTotal] = useState<string>("");
  const [totalKeys, setTotalKeys] = useState<number[]>([]);
  const [competence, setCompetence] = useState<CompetenceActivityClass | null>(null);

  useEffect(() => {
    const getCompetence = (): void => {
      let competenceForSkill = props.competences.find((item) => item.id === props.skill["Competence/Activity"][0]);
      competenceForSkill && setCompetence(competenceForSkill);
    };

    if (props.competences.length > 0) {
      getCompetence();
    }
  }, [props.competences, props.skill]);

  useEffect(() => {
    const getEvidenceCount = (): void => {
      const evidenceDefinitions = competence?.["Evidence Definitions"][0];
      const evidenceParts = evidenceDefinitions?.["Evidence parts"] || [];
      const evidencePartWithSkills = evidenceParts.filter((item) => item.Skills && item.Skills.length > 0)[0];

      const evidenceWithSkills = props.programEvidence.filter((item) => {
        const evidenceJSON = JSON.parse(item.evidenceJSON);
        const part = evidenceJSON[evidencePartWithSkills.Name];

        const onHoldReset = evidenceJSON?.OnHoldReset ?? 0;

        return part !== undefined && onHoldReset !== 1 && item.draft !== true;
      });

      const _total = props.skill.numberRequiredInDuration || "0";
      const _count =
        evidenceWithSkills.filter((item) => {
          const evidenceJSON = JSON.parse(item.evidenceJSON);
          const part = evidenceJSON[evidencePartWithSkills.Name];

          const achieved = part?.includes(props.skill.Name);

          return achieved && isSameMonth(new Date(), new Date(item.date));
        }).length || 0;

      setTotal(_total);
      setCount(_count);
      setTotalKeys(Array.from(Array(parseInt(_total, 10)).keys()));
    };

    if (allEvidence.state === "hasValue" && allEvidence.contents && competence) {
      getEvidenceCount();
    }
  }, [allEvidence, competence, props.programEvidence, props.skill]);

  return (
    <ProgramInfoCard title={props.skill.Name}>
      <>
        <div className="flex-col">
          <div className="font-inter text-[13px] leading-[1.54] tracking-[-0.05px] text-grey-70">
            {`To ensure recertification in ${competence?.Name}, you must complete ${total} piece${parseInt(total, 10) !== 1 ? "s" : ""} of CPD on ${props.skill.Name} per ${props.skill.durationDescription}`}
          </div>
          <div className="flex !flex-row shrink-[-1] h-[44px] items-end">
            <div className={`!text-acc-34px font-bold leading-[1.29] text-grey-60 ${count > 0 && "!text-[#06A14F]"}`}>
              {`${count}`}
            </div>
            <div className="!text-acc-15px font-bold leading-[1.33] tracking-default text-grey-60 mb-[5px]">
              &nbsp;{`/ ${parseInt(total, 10)}`}
            </div>
          </div>
          <div className="flex h-[4px] flex-1 self-end rounder-[2px] bg-white mb-[7px] overflow-hidden">
            {totalKeys.map((_, index) => (
              <div className="w-full flex flex-row" key={index}>
                <div className={`h-[4px] flex grow rounded-[2px] bg-grey-30 ${index < count ? "!bg-[#09AB55]" : ""}`} />
                {index !== totalKeys.length - 1 && <div className="w-[4px] h-[4px] bg-white" />}
              </div>
            ))}
          </div>
        </div>
        <ProgramCardButton
          buttonPressed={() => props.buttonPressed(competence)}
          buttonText="Add new evidence"
          icon={Icon_Add_Evidence}
        />
      </>
    </ProgramInfoCard>
  );
};

export default RequiredSkillCard;
