import { Children } from "react";
import "./ProfileComponents.css";

type Props = {
  title: string;
  children?: React.ReactNode;
};

const AccountCard = ({ title, children }: Props) => {
  return (
    <div className="accountCard">
      <div className="accountCardInner">
        <div className="accountCardTitle">{title}</div>
        <div className="accountCardContent">{children}</div>
      </div>
    </div>
  );
};

export default AccountCard;
