import { useEffect, useState } from "react";
import { EvidencePart } from "../../../Interfaces";
import "./EvidenceParts.css";
import { IonItem, IonLabel, IonRadio, IonRadioGroup } from "@ionic/react";
import { Text, View } from "@react-pdf/renderer";

type Props = {
  children?: React.ReactNode;
  style?: React.CSSProperties;
  definition: EvidencePart;
  onValueChanged?: (value: any) => void;
  data?: any;
  isDisplay?: boolean;
  isPDF?: boolean;
  pdfStyles: any;
  initialValue?: any;
};

const RadioButton: React.FC<Props> = ({
  children,
  style,
  definition,
  onValueChanged,
  data,
  isDisplay,
  isPDF,
  pdfStyles,
  initialValue,
}) => {
  const [value, setValue] = useState<string>(initialValue || "");
  const [listValues, setListValues] = useState<string[]>([]);

  useEffect(() => {
    const getListValues = (): void => {
      const values = definition?.["List of values"];

      if (values && values.length > 0) {
        let strings = values.split("\n");
        strings = strings.filter((item) => item.length > 0);

        setListValues(strings);
      }
    };

    definition && getListValues();
  }, [definition]);

  const valueChanged = (event: any): void => {
    const _value = event.detail.value;

    setValue(_value);
    onValueChanged && onValueChanged(_value);
  };

  if (isPDF) {
    return (
      <View>
        <Text style={pdfStyles.reflectionHeader}>{definition["Display Title"] || definition.Name}</Text>
        <Text style={pdfStyles.evidenceDate}>{data}</Text>
      </View>
    );
  }

  if (isDisplay) {
    return (
      <div className="readEvidenceBlock">
        <div className="readEvidenceHeader">{definition["Display Title"] || definition.Name}</div>
        <div className="readEvidenceText">{data}</div>
        <div className="h-[1px] w-full bg-grey-30 mt-4" />
      </div>
    );
  }

  return (
    <div>
      <IonRadioGroup color="light" id="editRoleRadioGroup" value={value} onIonChange={valueChanged}>
        {listValues.map((item, index) => {
          return (
            <div key={item}>
              <IonItem className="evidencePartsRadioContainer">
                <IonRadio
                  aria-label={item}
                  mode="md"
                  value={item}
                  justify="space-between"
                  className={`text-17px tracking-[-0.3] ${value === item ? "font-semibold" : ""}`}
                >
                  {item}
                </IonRadio>
              </IonItem>
            </div>
          );
        })}
      </IonRadioGroup>
    </div>
  );
};

export default RadioButton;
