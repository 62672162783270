import { useEffect, useState } from "react";

import _ from "lodash";

import "./HomeComponents.css";
import { IEvidence, INOSOverviewCard, IUser, StandardTag } from "../../Interfaces";
import roles from "../../assets/json/roles.json";
import standards from "../../assets/json/standards.json";
import { useRecoilStateLoadable } from "recoil";
import { evidenceAtom } from "../../state/State";
import { IonButton } from "@ionic/react";
import { EventRegister } from "react-native-event-listeners";
import { isAfter, subDays, subYears } from "date-fns";
import { useHistory } from "react-router";
import NOSStandardsGraph from "./NOSStandardsGraph";

const NOSOverviewCard = ({ userObject }: INOSOverviewCard) => {
  const history = useHistory();

  const [user, setUser] = useState<IUser | null>(userObject);
  const [evidence, setEvidence] = useRecoilStateLoadable<IEvidence[] | null>(evidenceAtom);

  const [optionalStandards, setOptionalStandards] = useState<any[] | undefined>([]);
  const [mandatoryStandards, setMandatoryStandards] = useState<any[] | undefined>([]);

  useEffect(() => {
    const getStandardTagsForRole = (_user: IUser): void => {
      if (_user && _user.role) {
        const role: string = _user.role;
        const roleObject = roles.find((item) => item.Name === role);

        const optional = roleObject?.OptionalRoles;
        const mandatory = roleObject?.MandatoryRoles;

        const mandatoryRoles = mandatory?.map((id) => standards.find((item) => item.id === id));
        const optionalRoles = optional?.map((id) => standards.find((item) => item.id === id));

        setOptionalStandards(_.orderBy(optionalRoles, ["Code"], ["asc"]) || []);

        setMandatoryStandards(_.orderBy(mandatoryRoles, ["Code"], ["asc"]) || []);
      }
    };

    setUser(userObject);
    userObject && getStandardTagsForRole(userObject);
  }, [userObject]);

  const goToBreakdown = async (): Promise<void> => {
    history.push("/dashboard/nos-overview");
  };

  const goToProfile = async (): Promise<void> => {
    EventRegister.emit("profile/edit-profile");
  };

  return (
    <div className="dashboardCard">
      <div className="overviewHeader">
        <div className="dashboardCardTitle">{"SWAST Major Incident Commander"}</div>
      </div>
      {/* {1 !== 1 ? ( */}
      {user?.role ? (
        <>
          {mandatoryStandards && mandatoryStandards.length > 0 && (
            <NOSStandardsGraph
              title="Mandatory Standards"
              standards={mandatoryStandards}
              evidence={evidence.state === "hasValue" && evidence.contents ? evidence.contents : []}
            />
          )}
          {optionalStandards && optionalStandards?.length > 0 && (
            <NOSStandardsGraph
              title="Optional Standards"
              standards={optionalStandards}
              evidence={evidence.state === "hasValue" && evidence.contents ? evidence.contents : []}
            />
          )}
          <IonButton mode="ios" className="accountButton" onClick={() => goToBreakdown()}>
            <div className="accountButtonText">{"Enter Incident Commander mode"}</div>
          </IonButton>
        </>
      ) : (
        <>
          <div className="overviewCardDetails">
            {"Incident Commander mode is only viewable when you have added a role to your profile. " +
              "If you want to view your NOS overview, please add your role by editing your profile using the button below."}
          </div>
          <IonButton mode="ios" className="accountButton" onClick={() => goToProfile()}>
            <div className="accountButtonText">{"Set your role"}</div>
          </IonButton>
        </>
      )}
    </div>
  );
};

export default NOSOverviewCard;
